/* eslint-disable default-case */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import makeAnimated from 'react-select/animated';
import MultiSelect, { StylesConfig } from 'react-select';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { getYear, getMonth } from 'date-fns';
import {
  getNotification,
  createNotification,
  updateNotification,
  deleteNotification,
} from '../../../services/Notification.service';
import { useAppSelector } from '../../../store';
import NotificationsType from '../../../types/notifications.type';
import SettingsNotificationsType from '../../../types/settingsNotifications.type';
import CompanyType from '../../../types/company.type';
import { theme } from '../../../global/styles/theme';
import FloatFooter from '../../../Components/FloatFooter';
// import QuestionTooltip from '../../../Components/QuestionTooltip';
// import TitleLabel from '../../../Components/TitleLabel';
import DividerForm from '../../../Components/DividerForm';
import '../../../custom.scss';

import {
  Container,
  Dot,
  GoBackIcon,
  ReportsSurvey,
  NavGoBack,
  NavGoBackContainer,
  NavGoBackName,
  Context,
  InputStyled,
  RowForm,
  Label,
  ButtonContainer,
  ButtonStyled,
  Header,
  LabelId,
  LoginSpinner,
  Select,
  Textarea,
  GroupButton,
  DatePickerStyled,
} from './styles';

interface CompaniesProps {
  value: string;
  label: string;
  isFixed?: boolean;
}
const NotificationsDetails = () => {
  const navigate = useNavigate();
  const { state }: { state: any } = useLocation();
  const { notificationId, companiesIds } = state || {
    notificationId: '',
    companiesIds: [],
  };
  const [today] = useState(new Date());
  today.setMinutes(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [companyId, setCompanyId] = useState<string[]>(companiesIds);
  const [messageType, setMessageType] = useState('');
  const [days, setDays] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [titleButton, setTitleButton] = useState('Adicionar');
  const [filterTypeRecipient, setFilterTypeRecipient] =
    useState<boolean>(false);
  const [filterTypeHolder, setFilterTypeHolder] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<boolean>(true);
  const [filterProdPlans, setFilterProdPlans] = useState<boolean | string>(
    'init',
  );
  const [timeSend, setTimeSend] = useState(today);
  const [fixedDate, setFixedDate] = useState(today);
  const [monthPeriod, setMonthPeriod] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [surveyPeriod, setSurveyPeriod] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [companiesList, setCompaniesList] = useState<CompaniesProps[]>();
  const [companySelected] = useState<string[] | undefined>(companiesIds);

  const [initialDelay, setInitialDelay] = useState('');
  const [finalDelay, setFinalDelay] = useState('');

  const [startMonthPeriod, endMonthPeriod] = monthPeriod;
  const [startSurveyPeriod, endSurveyPeriod] = surveyPeriod;

  const company = useAppSelector(
    stateSelector => stateSelector.companyReducer.companySelected,
  );
  const companies = useAppSelector(
    stateSelector => stateSelector.companyReducer.companies,
  );

  const changeInputDays = (number: string) => {
    const numberTmp = Number(number);

    if (!numberTmp) setDays('');

    if (numberTmp >= 1 && numberTmp <= 31) setDays(number);
  };

  const colourStyles: StylesConfig = {
    container: (styles: any) => ({
      ...styles,
      marginTop: '0.6rem',
      borderRadius: 8,
      borderWidth: 0.5,
      borderStyle: 'solid',
      borderColor: theme.colors.borderInput,
    }),
    control: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      borderWidth: 0.5,
      borderStyle: 'none',
      ':hover': {
        ...styles[':hover'],
        borderColor: theme.colors.primary,
        boxShadow: `0 0 0 1px ${theme.colors.primary}`,
      },
    }),
  };

  const animatedComponents = makeAnimated();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleGoBack = () => {
    navigate('/customers/notifications');
  };

  useEffect(() => {
    setCompaniesList(
      companies.map((comp: CompanyType) => {
        return {
          value: comp.cnpj,
          label: comp.name,
        };
      }),
    );
  }, [companies]);

  const filterFieldsSelecteds = useCallback((fields: string, settings: any) => {
    setMessageType(settings.messageType);

    switch (fields) {
      case 'registration':
        if (settings.time_send) {
          const date = new Date();
          const time = settings.time_send.split(':');
          date.setHours(Number(time[0]));
          date.setMinutes(Number(time[1]));
          setTimeSend(date);
        }
        if (settings.messageType === 'monthPeriod') {
          setDays(settings.days);
          setMonthPeriod([
            new Date(settings.monthPeriod[0]),
            new Date(settings.monthPeriod[1]),
          ]);
        }
        if (settings.messageType === 'fixedDate') {
          setFixedDate(new Date(settings.fixedDate));
        }
        if (settings.messageType === 'contractActivation') {
          setDays(settings.days);
        }
        break;
      case 'contract':
        if (settings.filterProdPlans.length === 2) setFilterProdPlans('init');
        else if (settings.filterProdPlans[0] === 'productions') {
          setFilterProdPlans(true);
        } else setFilterProdPlans(false);
        break;
      case 'financial':
        if (settings.time_send) {
          const date = new Date();
          const time = settings.time_send.split(':');
          date.setHours(Number(time[0]));
          date.setMinutes(Number(time[1]));
          setTimeSend(date);
        }
        if (settings.messageType === 'qtdOverdueInstallments') {
          setFinalDelay(settings.qtdOverdueInstallments.finalDelay);
          setInitialDelay(settings.qtdOverdueInstallments.initialDelay);
        }
        if (settings.days) setDays(settings.days);
        if (settings.monthPeriod)
          setMonthPeriod([
            new Date(settings.monthPeriod[0]),
            new Date(settings.monthPeriod[1]),
          ]);
        break;
      case 'survey':
        setTitle(settings.title);
        setDescription(settings.description);
        setMessageType('survey');
        setSurveyPeriod([
          new Date(settings.start_period),
          new Date(settings.end_period),
        ]);
        break;
    }
  }, []);

  const getNotificationOne = useCallback(async () => {
    const { data, status, msg } = await getNotification(notificationId);

    if (!status) toast.error(msg);

    setTitle(data.name);
    setDescription(data.message);
    setNotificationType(data.notificationType);
    setFilterStatus(data.status);

    data.userTypes.forEach((typeUser: string) => {
      if (typeUser === 'holder') setFilterTypeHolder(true);
      if (typeUser === 'dependent') setFilterTypeRecipient(true);
    });

    filterFieldsSelecteds(data.notificationType, data.settings);
  }, [filterFieldsSelecteds, notificationId]);

  useEffect(() => {
    if (notificationId) {
      setTitleButton('Atualizar');
      getNotificationOne();
    }
  }, [getNotificationOne, notificationId]);

  const filterFields = (fields: string) => {
    const values: SettingsNotificationsType = {};
    values.messageType = messageType;

    switch (fields) {
      case 'registration':
        if (timeSend) {
          let hours = timeSend.getHours();
          hours = String(hours).length < 2 ? Number(`0${hours}`) : hours;
          values.time_send = `${hours}:00`;
        }
        if (messageType === 'monthPeriod') {
          values.days = days;
          values.monthPeriod = monthPeriod;
        }
        if (messageType === 'fixedDate') {
          values.fixedDate = fixedDate;
        }
        if (messageType === 'contractActivation') {
          values.whenSend = 'daysLater';
          values.days = days;
        }
        break;
      case 'contract':
        if (filterProdPlans === 'init') {
          values.filterProdPlans = ['productions', 'plans'];
        } else if (filterProdPlans) {
          values.filterProdPlans = ['productions'];
        } else {
          values.filterProdPlans = ['plans'];
        }
        break;
      case 'financial':
        values.days = days;
        values.monthPeriod = monthPeriod;

        if (timeSend) {
          let hours = timeSend.getHours();
          hours = String(hours).length < 2 ? Number(`0${hours}`) : hours;
          values.time_send = `${hours}:00`;
        }
        if (messageType === 'qtdOverdueInstallments') {
          values.qtdOverdueInstallments = {
            finalDelay,
            initialDelay,
          };
        }
        break;
      case 'survey':
        values.title = title;
        values.description = description;
        values.start_period = String(surveyPeriod[0]);
        values.end_period = String(surveyPeriod[1]);
        values.messageType = 'survey';
        break;
    }
    return values;
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    message: Yup.string().required('A descrição é obrigatória'),
    notificationType: Yup.string().required(
      'O Tipo de Notificação é obrigatório',
    ),
    companies: Yup.array().of(Yup.string().required('A Empresa é obrigatória')),
    settings: Yup.object().shape({
      messageType: Yup.string().required(
        'Os campos do Tipo de Notificação, são obrigatórios',
      ),
    }),
  });

  const handleAddNotification = async () => {
    const userTypes = [];

    if (filterTypeRecipient) userTypes.push('dependent');
    if (filterTypeHolder) userTypes.push('holder');

    if (!filterTypeRecipient && !filterTypeHolder) {
      setLoading(false);
      return toast.error('Selecione um tipo de usuário');
    }

    const values: NotificationsType = {
      name: title,
      message: description,
      created_by: company._id,
      status: filterStatus,
      userTypes,
      type: notificationType !== 'survey' ? 'notification' : 'survey',
      notificationType,
      companies: companyId,
      id_group: company.id_group,
      settings: filterFields(notificationType),
    };

    const isValid = await schema
      .validate(values)
      .catch(({ errors }) => errors[0]);

    if (!Object.is(isValid, values)) {
      setLoading(false);
      return toast.error(isValid);
    }

    const { status, msg } = await createNotification(values);

    setLoading(false);
    if (!status) return toast.error(msg);

    toast.success('Notificação adicionada com sucesso!');
    return handleGoBack();
  };

  const handleUpdateNotification = async () => {
    const userTypes = [];

    if (filterTypeRecipient) userTypes.push('dependent');
    if (filterTypeHolder) userTypes.push('holder');

    if (!filterTypeRecipient && !filterTypeHolder) {
      setLoading(false);
      return toast.error('Selecione um tipo de usuário');
    }

    const values: NotificationsType = {
      name: title,
      message: description,
      created_by: company._id,
      status: filterStatus,
      userTypes,
      type: notificationType !== 'survey' ? 'notification' : 'survey',
      notificationType,
      companies: companyId,
      id_group: company.id_group,
      settings: filterFields(notificationType),
    };

    const isValid = await schema
      .validate(values)
      .catch(({ errors }) => errors[0]);

    if (!Object.is(isValid, values)) {
      setLoading(false);
      return toast.error(isValid);
    }

    const { status, msg } = await updateNotification(notificationId, values);

    setLoading(false);
    if (!status) return toast.error(msg);

    toast.success('Notificação atualizada com sucesso!');
    return handleGoBack();
  };

  const handleDeleteNotification = async () => {
    const { status, msg } = await deleteNotification(notificationId);

    if (!status) return toast.error(msg);

    toast.success('Notificação deletada com sucesso!');
    return handleGoBack();
  };

  const handleSurvey = () => {
    navigate('/customers/notifications-details/ResultsSatisfactionSurveys', {
      state: { notificationId, groupId: company.id_group },
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    if (notificationId) return handleUpdateNotification();
    return handleAddNotification();
  };

  return (
    <>
      <FloatFooter>
        <ButtonContainer>
          {notificationId && (
            <ButtonStyled variant="secondary" onClick={handleShow}>
              Deletar
            </ButtonStyled>
          )}
          <ButtonStyled
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <LoginSpinner animation="border" variant="light" />
            ) : (
              titleButton
            )}
          </ButtonStyled>
        </ButtonContainer>
      </FloatFooter>
      <Container>
        <Header>
          <NavGoBackContainer onClick={handleGoBack}>
            <GoBackIcon />
            <NavGoBack>Lista</NavGoBack>
            {title && <Dot />}
            <NavGoBackName>{title || ''}</NavGoBackName>
          </NavGoBackContainer>
          {notificationId && (
            <LabelId
              style={{ marginLeft: 'auto' }}
            >{`ID: ${notificationId}`}</LabelId>
          )}
          {notificationType === 'survey' && notificationId && (
            <ButtonStyled variant="primary" onClick={handleSurvey}>
              <ReportsSurvey
                style={{ marginRight: '5px', marginTop: '-2px' }}
              />
              Relatório das avaliações
            </ButtonStyled>
          )}
        </Header>
        <Context>
          <RowForm>
            <Label>Título</Label>
            <InputStyled
              name="name"
              type="text"
              value={title}
              onChange={(e: any) => setTitle(e.target.value)}
            />
          </RowForm>

          <RowForm>
            <Label>Tipo da notificação</Label>
            <GroupButton>
              <ButtonStyled
                style={{ marginLeft: 0 }}
                onClick={() => setNotificationType('registration')}
                variant={
                  notificationType === 'registration'
                    ? 'outline-primary'
                    : 'outline-secondary'
                }
              >
                Cadastral
              </ButtonStyled>
              <ButtonStyled
                onClick={() => setNotificationType('financial')}
                variant={
                  notificationType !== 'registration' &&
                  notificationType === 'financial'
                    ? 'outline-primary'
                    : 'outline-secondary'
                }
              >
                Financeiro
              </ButtonStyled>
              <ButtonStyled
                onClick={() => setNotificationType('survey')}
                variant={
                  notificationType !== 'financial' &&
                  notificationType === 'survey'
                    ? 'outline-primary'
                    : 'outline-secondary'
                }
              >
                Avaliação
              </ButtonStyled>
            </GroupButton>
          </RowForm>

          <RowForm>
            <Label>Empresas</Label>
            <MultiSelect
              theme={(themeSelect: any) => ({
                ...themeSelect,
                colors: {
                  ...themeSelect.colors,
                  primary: theme.colors.primary,
                },
              })}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione as empresas"
              styles={colourStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              defaultValue={
                notificationId &&
                companySelected?.map((comp: string) => {
                  return companies
                    .map((companyTmp: CompanyType) => {
                      return {
                        value: companyTmp.cnpj,
                        label: companyTmp.name,
                      };
                    })
                    .filter(
                      (companyList: CompaniesProps) =>
                        companyList.value === comp,
                    )[0];
                })
              }
              isMulti
              options={companiesList}
              onChange={(e: any) =>
                setCompanyId(
                  e.map((companyTmp: CompaniesProps) => companyTmp.value),
                )
              }
            />
          </RowForm>
          <RowForm>
            <Label>Usuário</Label>
            <GroupButton>
              <ButtonStyled
                style={{ marginLeft: 0 }}
                onClick={() => setFilterTypeRecipient(!filterTypeRecipient)}
                variant={
                  filterTypeRecipient ? 'outline-primary' : 'outline-secondary'
                }
              >
                Beneficiário
              </ButtonStyled>
              <ButtonStyled
                onClick={() => setFilterTypeHolder(!filterTypeHolder)}
                variant={
                  filterTypeHolder ? 'outline-primary' : 'outline-secondary'
                }
              >
                Titular
              </ButtonStyled>
            </GroupButton>
          </RowForm>
          <RowForm>
            <Label>Status</Label>
            <GroupButton>
              <ButtonStyled
                style={{ marginLeft: 0 }}
                onClick={() => setFilterStatus(true)}
                variant={filterStatus ? 'outline-primary' : 'outline-secondary'}
              >
                Ativo
              </ButtonStyled>
              <ButtonStyled
                onClick={() => setFilterStatus(false)}
                variant={
                  !filterStatus ? 'outline-primary' : 'outline-secondary'
                }
              >
                Inativo
              </ButtonStyled>
            </GroupButton>
          </RowForm>
          <DividerForm />

          {(notificationType === 'registration' ||
            notificationType === 'financial') && (
            <RowForm>
              <Label>Horário de Envio</Label>
              <DatePickerStyled
                isClearable
                selected={timeSend}
                placeholderText="Selecione o Horário"
                onChange={(date: Date) => setTimeSend(date)}
                showTimeSelect
                showTimeSelectOnly
                timeFormat="HH:mm"
                timeIntervals={60}
                timeCaption="time"
                dateFormat="HH:mm aa"
                locale="pt-BR"
              />
            </RowForm>
          )}

          {notificationType === 'survey' && (
            <RowForm>
              <Label>Período da pesquisa</Label>
              <DatePickerStyled
                selectsRange
                isClearable
                startDate={startSurveyPeriod}
                endDate={endSurveyPeriod}
                placeholderText="Período entre datas"
                onChange={(date: [Date | null, Date | null]) =>
                  date && setSurveyPeriod(date)
                }
                minDate={new Date(getYear(today), getMonth(today), 1)}
                showMonthYearPicker
                showFullMonthYearPicker
                dropdownMode="select"
                locale="pt-BR"
                dateFormat="MM/yyyy"
                withPortal
              />
            </RowForm>
          )}

          {notificationType === 'registration' && (
            <>
              <RowForm>
                <Label>Tipo de Envio</Label>
                <Select
                  onChange={(e: any) => setMessageType(e.target.value)}
                  value={messageType}
                >
                  <option value="">Selecione o Tipo de Envio</option>
                  <option value="birthday">Aniversário</option>
                  <option value="monthPeriod">Mensal</option>
                  <option value="fixedDate">Data Fixa</option>
                  <option value="contractActivation">
                    Ativação do Contrato
                  </option>
                </Select>
              </RowForm>
              {messageType === 'fixedDate' && (
                <RowForm>
                  <Label>Data Fixa</Label>
                  <DatePickerStyled
                    isClearable
                    selected={fixedDate}
                    placeholderText="Selecione a Data"
                    onChange={(date: Date) => setFixedDate(date)}
                    minDate={today}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    withPortal
                  />
                </RowForm>
              )}
              {messageType === 'monthPeriod' && (
                <>
                  <RowForm>
                    <Label>Dia</Label>
                    <InputStyled
                      name="days"
                      type="number"
                      value={days}
                      onChange={(e: any) => changeInputDays(e.target.value)}
                    />
                  </RowForm>
                  <RowForm>
                    <Label>Período</Label>
                    <DatePickerStyled
                      selectsRange
                      isClearable
                      startDate={startMonthPeriod}
                      endDate={endMonthPeriod}
                      placeholderText="Período entre datas"
                      onChange={(date: [Date | null, Date | null]) =>
                        date && setMonthPeriod(date)
                      }
                      minDate={new Date(getYear(today), getMonth(today), 1)}
                      showMonthYearPicker
                      showFullMonthYearPicker
                      dropdownMode="select"
                      locale="pt-BR"
                      dateFormat="MM/yyyy"
                      withPortal
                    />
                  </RowForm>
                </>
              )}
              {messageType === 'contractActivation' && (
                <RowForm>
                  <Label>Dias após ativação</Label>
                  <InputStyled
                    name="days"
                    type="number"
                    value={days}
                    onChange={(e: any) => changeInputDays(e.target.value)}
                  />
                </RowForm>
              )}
            </>
          )}
          {notificationType === 'contract' && (
            <>
              <RowForm>
                <Label>Tipo de Envio</Label>
                <Select
                  onChange={(e: any) => setMessageType(e.target.value)}
                  value={messageType}
                >
                  <option value="">Selecione o Tipo de Envio</option>
                  <option value="contractActivation">
                    Ativação do Contrato
                  </option>
                  <option value="contractRelease">Liberação do Contrato</option>
                  <option value="cancellationContract">
                    Cancelamento do Contrato
                  </option>
                  <option value="suspensionContract">
                    Suspensão do contrato
                  </option>
                </Select>
              </RowForm>
              <RowForm>
                <Label>Produção/Planos</Label>
                <GroupButton>
                  <ButtonStyled
                    style={{ marginLeft: 0 }}
                    onClick={() => setFilterProdPlans(true)}
                    variant={
                      filterProdPlans && filterProdPlans !== 'init'
                        ? 'outline-primary'
                        : 'outline-secondary'
                    }
                  >
                    Produção
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={() => setFilterProdPlans(false)}
                    variant={
                      !filterProdPlans && filterProdPlans !== 'init'
                        ? 'outline-primary'
                        : 'outline-secondary'
                    }
                  >
                    Planos
                  </ButtonStyled>
                </GroupButton>
              </RowForm>
            </>
          )}
          {notificationType === 'financial' && (
            <>
              <RowForm>
                <Label>Tipo de Envio</Label>
                <Select
                  onChange={(e: any) => setMessageType(e.target.value)}
                  value={messageType}
                >
                  <option value="">Selecione o Tipo de Envio</option>
                  <option value="qtdOverdueInstallments">
                    Qtd Parcelas em Atraso
                  </option>
                  <option value="lastInstallmentDue">
                    Última Parcela Vencida
                  </option>
                  <option value="nextInstallmentToWin">
                    Próxima Parcela a vencer
                  </option>
                </Select>
              </RowForm>
              {messageType === 'qtdOverdueInstallments' && (
                <RowForm style={{ marginBottom: '1rem' }}>
                  <Label>Quantidade</Label>
                  <RowForm style={{ marginBottom: '1rem' }}>
                    <Row className="g-2">
                      <Col sm="5">
                        <Select
                          onChange={(e: any) => setInitialDelay(e.target.value)}
                          value={initialDelay}
                        >
                          <option value="">Atraso inicial</option>
                          <option value="oneInstallment">1 parcela</option>
                          <option value="twoInstallment">2 parcelas</option>
                          <option value="threeInstallment">3 parcelas</option>
                          <option value="forInstallment">4 parcelas</option>
                          <option value="fiveInstallment">5 parcelas</option>
                        </Select>
                      </Col>
                      <Form.Label
                        column
                        sm="1"
                        style={{
                          fontSize: '13px',
                          paddingTop: '12px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        até
                      </Form.Label>
                      <Col sm="6">
                        <Select
                          onChange={(e: any) => setFinalDelay(e.target.value)}
                          value={finalDelay}
                        >
                          <option value="">Atraso final</option>
                          <option value="oneInstallment">1 parcela</option>
                          <option value="twoInstallment">2 parcelas</option>
                          <option value="threeInstallment">3 parcelas</option>
                          <option value="forInstallment">4 parcelas</option>
                          <option value="fiveInstallment">5 parcelas</option>
                          <option value="moreThanFiveInstallments">
                            Acima de 5 parcelas
                          </option>
                        </Select>
                      </Col>
                    </Row>
                  </RowForm>
                </RowForm>
              )}
              <RowForm>
                <Label>Período</Label>
                <DatePickerStyled
                  selectsRange
                  isClearable
                  startDate={startMonthPeriod}
                  endDate={endMonthPeriod}
                  placeholderText="Período entre datas"
                  onChange={(date: [Date | null, Date | null]) =>
                    date && setMonthPeriod(date)
                  }
                  minDate={new Date(getYear(today), getMonth(today), 1)}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  dropdownMode="select"
                  locale="pt-BR"
                  dateFormat="MM/yyyy"
                  withPortal
                />
              </RowForm>
            </>
          )}
          <RowForm>
            <Label>Descrição</Label>
            <Textarea
              name="descricao"
              as="textarea"
              placeholder="Escreva uma descrição..."
              rows={6}
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </RowForm>
        </Context>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Atenção!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Realmente deseja deletar a notificação {title}?
          </Modal.Body>
          <Modal.Footer>
            <ButtonStyled variant="secondary" onClick={handleClose}>
              Cancelar
            </ButtonStyled>
            <ButtonStyled variant="danger" onClick={handleDeleteNotification}>
              Deletar
            </ButtonStyled>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default NotificationsDetails;
