/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import { format } from 'date-fns';
import TitlePage from '../../../Components/TitlePage';
import Flag from '../../../Components/Flag';
import { getAdverts } from '../../../services/Adverts.service';
import { useAppSelector } from '../../../store';
import AdvertsType from '../../../types/adverts.type';
import SkeletonListEndBar from '../../../Components/SkeletonListEndBar';
import ptBR from '../../../utils/locale';

import {
  Container,
  Context,
  AlertTable,
  Header,
  ButtonFilter,
  THead,
  TableStyled,
  Td,
  Th,
  Tr,
  PaginationStyled,
  AreaPagination,
  DescriptionPagination,
} from './styles';

const Adverts: React.FC = () => {
  const ADVERTS_PER_PAGE = 10;
  const HOME_PAGE = 1;

  const navigate = useNavigate();
  const company = useAppSelector(
    (state: any) => state.companyReducer.companySelected,
  );

  const [adverts, setAdverts] = useState<AdvertsType[]>([]);
  const [totalAdverts, setTotalAdverts] = useState<number>(0);
  const [paginationItem, setPaginationItem] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(HOME_PAGE);
  const [loading, setLoading] = useState<boolean>(false);
  const [descriptionPagination, setDescriptionPagination] = useState('');
  const [pageInit, setPageInit] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);

  const advertsInitial: AdvertsType = {
    _id: '',
    id_group: '',
    status: '',
    title: '',
    action_button_text: '',
    action_button_url: '',
    media_url: '',
  };

  const getAllAdverts = useCallback(
    async (page: number, limit: number) => {
      const { data, status, msg } = await getAdverts(
        company.id_group,
        page,
        limit,
      );

      if (!status) {
        setLoading(false);
        toast.error(msg);
      } else {
        setAdverts(data?.campaigns);
        setTotalAdverts(data?.totalDocs);

        const countItem = [];
        for (
          let num = 1;
          num <= Math.ceil(data.totalDocs / ADVERTS_PER_PAGE);
          num += 1
        ) {
          countItem.push(num);
        }

        if (countItem.length <= 0 && data?.campaigns.length > 0) {
          setPaginationItem([1]);
        } else setPaginationItem(countItem);
        setLoading(false);
      }
    },
    [company],
  );

  const handleViewContractDetail = (terms: AdvertsType) => {
    navigate('/customers/adverts/adverts-details', {
      state: { advertId: terms._id },
    });
  };

  const changePage = (page: number) => {
    if (page > 0 && page <= paginationItem.length) {
      if (page > 5) {
        setPageInit(page - 5);
        setPageLimit(page);
      } else {
        setPageInit(0);
        setPageLimit(5);
      }

      setSelectedPage(page);
      getAllAdverts(page, ADVERTS_PER_PAGE);
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllAdverts(HOME_PAGE, ADVERTS_PER_PAGE);
  }, [getAllAdverts]);

  useEffect(() => {
    setDescriptionPagination(
      `${
        (selectedPage - 1) * ADVERTS_PER_PAGE +
        adverts.length -
        (adverts.length - 1)
      }-${
        (selectedPage - 1) * ADVERTS_PER_PAGE + adverts.length
      } de ${totalAdverts} Campanhas`,
    );
  }, [adverts.length, selectedPage, totalAdverts]);

  const formatDate = (date_from: string, date_to: string) => {
    const dateFrom = format(new Date(date_from), 'dd/MM/yyyy', {
      locale: ptBR,
    });

    const dateTo = format(new Date(date_to), 'dd/MM/yyyy', {
      locale: ptBR,
    });

    return `${dateFrom} - ${dateTo}`;
  };

  return (
    <Container>
      <Header>
        <TitlePage title="Lista de campanhas" />
        <ButtonFilter
          variant="primary"
          onClick={() => handleViewContractDetail(advertsInitial)}
        >
          Adicionar campanhas
        </ButtonFilter>
      </Header>
      <Context>
        {loading && <SkeletonListEndBar />}
        {!loading && (
          <TableStyled hover>
            <THead>
              <tr>
                <Th>Nome</Th>
                <Th>Duração</Th>
                <Th>Status</Th>
              </tr>
            </THead>
            <tbody>
              {adverts.map((advert: AdvertsType) => (
                <Tr
                  key={advert._id}
                  onClick={() => handleViewContractDetail(advert)}
                >
                  <Td>{advert.title}</Td>
                  <Td>
                    {formatDate(
                      String(advert.date_from),
                      String(advert.date_to),
                    )}
                  </Td>
                  <Td>
                    <Flag
                      text={advert.status === 'active' ? 'Ativo' : 'Inativo'}
                      status={
                        advert.status === 'active' ? 'success' : 'warning'
                      }
                    />
                  </Td>
                </Tr>
              ))}
            </tbody>
            {adverts.length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan={100}>
                    <AreaPagination>
                      <DescriptionPagination style={{ visibility: 'hidden' }}>
                        {descriptionPagination}
                      </DescriptionPagination>
                      <PaginationStyled size="sm">
                        <Pagination.First
                          onClick={() => changePage(HOME_PAGE)}
                        />
                        <Pagination.Prev
                          onClick={() => changePage(selectedPage - 1)}
                        />
                        {paginationItem
                          .slice(pageInit, pageLimit)
                          .map((page: number) => (
                            <Pagination.Item
                              key={page}
                              active={page === selectedPage}
                              onClick={() => changePage(page)}
                            >
                              {page}
                            </Pagination.Item>
                          ))}
                        <Pagination.Next
                          onClick={() => changePage(selectedPage + 1)}
                        />
                        <Pagination.Last
                          onClick={() => changePage(paginationItem.length)}
                        />
                      </PaginationStyled>
                      <DescriptionPagination>
                        {descriptionPagination}
                      </DescriptionPagination>
                    </AreaPagination>
                  </td>
                </tr>
              </tfoot>
            )}
          </TableStyled>
        )}
        {!loading && adverts.length <= 0 && (
          <AlertTable>Nenhum campanha encontrado</AlertTable>
        )}
      </Context>
    </Container>
  );
};

export default Adverts;
