import FormattedResponseType from '../types/formattedResponse.type';
import NotificationsType from '../types/notifications.type';
import Api from './api';

export const getNotifications = async (
  groupId: string,
  page?: number,
  limit?: number,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    if (limit && page) {
      response = await Api.get(
        `notification/settings/${groupId}/${page}/${limit}/list`,
      );
    } else {
      response = await Api.get(`notification/settings/${groupId}/1/6/list`);
    }

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const updateNotification = async (
  notificationId: string,
  data: NotificationsType,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.put(
      `notification/settings/${notificationId}/update`,
      data,
    );

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const createNotification = async (
  data: NotificationsType,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.post(`notification/settings/create`, data);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const deleteNotification = async (
  notificationId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.delete(
      `notification/settings/${notificationId}/delete`,
    );

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const getNotification = async (
  notificationId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.get(`notification/settings/${notificationId}/show`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};
