import styled, { css } from 'styled-components';
import {
  Spinner,
  Tab,
  Button,
  Modal,
  Table,
  Nav,
  Pagination,
} from 'react-bootstrap';

import Input from '../../../Components/Input';

import { ReactComponent as ArrowLeftIcon } from '../../../assets/svgs/arrow-left.svg';
import { ReactComponent as UserCheck } from '../../../assets/svgs/user-check.svg';
import { ReactComponent as User } from '../../../assets/svgs/user.svg';
import { ReactComponent as Flag } from '../../../assets/svgs/flag-abas.svg';
import { ReactComponent as Financial } from '../../../assets/svgs/dollar-abas.svg';
import { ReactComponent as AttentionList } from '../../../assets/svgs/attention-list.svg';
import { ReactComponent as CheckList } from '../../../assets/svgs/check-list.svg';
import { ReactComponent as TimeList } from '../../../assets/svgs/time-list.svg';

import { theme } from '../../../global/styles/theme';

interface StatusProps {
  status?: string;
}

const { colors } = theme;

export const Container = styled.div`
  width: 100%;
  margin: 4.5rem auto;
  padding-left: 8rem;
  padding-right: 1.6rem;
`;

export const Context = styled.div`
  display: flex;
  width: 100%;
  margin: 2rem auto;
  flex-direction: row;
`;

export const LoginSpinner = styled(Spinner)`
  height: 1.4rem;
  width: 1.4rem;
`;

export const NavGoBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const NavGoBack = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 0;
  color: ${colors.text.tHead};
`;

export const NavGoBackName = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  color: ${colors.text.title};
`;

export const GoBackIcon = styled(ArrowLeftIcon)`
  margin-right: 1rem;
`;

export const UserCheckIcon = styled(UserCheck)``;

export const AttentionListIcon = styled(AttentionList)``;

export const CheckListIcon = styled(CheckList)``;

export const TimeListIcon = styled(TimeList)``;

export const FlagIcon = styled(Flag)`
  margin-right: 0.2rem;
`;

export const FinancialIcon = styled(Financial)`
  margin-right: 0.2rem;
`;

export const UserIcon = styled(User)`
  margin-right: 0.2rem;
`;

export const Dot = styled.div`
  border-radius: 50%;
  background-color: ${colors.text.tHead};
  height: 6px;
  width: 6px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 14rem;
  padding: 0.1rem;
  margin-bottom: 2rem;
  margin-right: 1.5rem;
  background: ${colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  min-width: 20rem;
  height: auto;

  .nav-link {
    background: ${colors.borderInput};
    color: ${colors.text.title};
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 5px 0px 3px rgba(0, 0, 0, 0.08);

    float: left;
    position: relative;
    margin-left: 0.2rem;
  }

  .nav-link:before,
  .nav-link:after {
    content: '';
    position: absolute;
    height: 10px;
    width: 20px;
    bottom: 0;
  }

  .nav-link:after {
    right: -20px;
    border-radius: 0 0 0 8px;
    box-shadow: -10px 0 0 0 ${colors.borderInput};
    transition: 0.2s;
  }

  .nav-link:before {
    left: -20px;
    border-radius: 0 0 8px 0;
    box-shadow: 10px 0 0 0 ${colors.borderInput};
    transition: 0.2s;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background: ${colors.white};
    color: ${colors.text.tHead};
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 5px -1px 3px rgba(0, 0, 0, 0.08);
    z-index: 1;

    ::before {
      box-shadow: 10px 0 0 0 ${colors.white};
      transition: 0.2s;
    }

    ::after {
      box-shadow: -10px 0 0 0 ${colors.white};
      transition: 0.2s;
    }
  }

  .flex-row {
    min-width: 35rem;
  }
`;

export const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const ImgProfile = styled.img`
  width: 6.5rem;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
`;

export const CustomerName = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2rem;
  margin-bottom: 0.6rem;
  color: ${colors.text.title};
  text-align: center;
`;

export const LastLoginText = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  color: ${colors.text.tHead};
`;

export const BodyDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
`;

export const RowDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.8rem;
`;

export const TabContent = styled(Tab.Content)`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
`;

export const HeaderTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const BodyTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const TitleTab = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
  color: ${colors.text.title};
  text-align: center;
`;

export const ButtonStyled = styled(Button)`
  border-radius: 10px;
`;

export const RowForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 34rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const InputStyled = styled(Input)`
  max-width: 34rem;
  width: 100%;
  margin-top: 0.6rem;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 1rem;
  margin-top: 2rem;
`;

export const ContentStatusActive = styled.div<{ status: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: ${colors.white};
  border-radius: 15px;
  height: 3rem;
  width: 100%;
  margin-top: 0.8rem;
  border: none;

  ${(props: { status: boolean }) => {
    if (props.status)
      return css`
        color: ${colors.flag.warning.primary};
        background: ${colors.flag.warning.background};
      `;

    return css`
      background: ${colors.green};
    `;
  }};
`;

export const ModalContent = styled(Modal)`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

  .modal-content {
    border-radius: 10px;
    border: none;
    width: 92%;
  }

  .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    border: none;
  }

  .modal-footer {
    border: none;
  }

  .modal-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 24px;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 20rem;
  width: 100%;
`;

export const ModalSubTitle = styled.h3`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 1.6rem;
`;

export const ModalDivider = styled.div`
  border-left: 1px solid ${colors.background};
  height: 5.6rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

export const ModalRowForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const CounterNotification = styled.div`
  background: ${colors.flag.error.background};
  color: ${colors.flag.error.primary};
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  top: 11.6rem;
  right: 2.6rem;
`;

export const TableStyled = styled(Table)`
  background-color: ${colors.white};
  border-collapse: separate;
  border-spacing: 0;
`;

export const THead = styled.thead`
  color: ${colors.text.tHead};
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  border-bottom-color: ${colors.background};
`;

export const Th = styled.th`
  padding: 1rem 0.5rem !important;
  padding-left: 1rem !important;
`;

export const Td = styled.td`
  padding-left: 1rem !important;
  color: ${colors.text.title};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
`;

export const Tr = styled.tr`
  cursor: pointer;
`;

export const NavItem = styled(Nav.Item)`
  cursor: pointer;
`;

export const NavLink = styled(Nav.Link)`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
`;

export const GroupButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FinancialContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CardItemFinancial = styled.div<StatusProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 0.8rem;
  margin-bottom: 0.5rem;

  ${(props: StatusProps) => {
    switch (props.status) {
      case 'success':
        return css`
          background: ${colors.flag.blue.background};
        `;
      case 'warning':
        return css`
          background: ${colors.flag.grey.background};
        `;
      case 'attention':
        return css`
          background: ${colors.flag.error.background};
        `;
      default:
        return css`
          background: ${colors.flag.grey.background};
        `;
    }
  }}
`;

export const ValueCard = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: ${colors.text.title};
`;

export const TitleCard = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.text.tHead};
  margin-bottom: 0.3rem;
`;

export const DescriptionCard = styled.h3<StatusProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: right;

  ${(props: StatusProps) => {
    switch (props.status) {
      case 'success':
        return css`
          color: ${colors.flag.blue.primary};
        `;
      case 'warning':
        return css`
          color: ${colors.flag.grey.primary};
        `;
      case 'attention':
        return css`
          color: ${colors.flag.error.primary};
        `;
      default:
        return css`
          color: ${colors.flag.grey.primary};
        `;
    }
  }}
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const GroupDiscriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 1rem;
`;

export const AlertTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 0.8rem;
  background: ${colors.flag.error.background};
  color: ${colors.flag.error.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const PaginationStyled = styled(Pagination)`
  margin: 0.6rem 0;
  margin-right: 0.6rem;
  justify-content: flex-end;
`;

export const AreaPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DescriptionPagination = styled.span`
  font-size: 12px;
`;
