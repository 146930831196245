import styled from 'styled-components';
import { ReactComponent as InputAdsExample } from '../../assets/svgs/ad-example.svg';

export const AdsExampleStyled = styled.div`
  padding: 0.5rem 0rem 0rem 0.3rem;
  margin: 6rem auto auto;
  display: table;
`;
export const IconAdsExample = styled(InputAdsExample)`
  width: 100%;
  height: 100%;
  max-width: 17rem;
`;
