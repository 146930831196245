import React from 'react';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../Components/TitlePage';
import {
  Container,
  Context,
  Card,
  IconCompany,
  TitleCard,
  DescriptionCard,
} from './styles';

import CompanyType from '../../types/company.type';
import { useAppSelector, useAppDispatch } from '../../store';
import {
  switchCompany,
  CompanySelected,
} from '../../store/slices/Company/Company.slice';

const Main: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const companies = useAppSelector(state => state.companyReducer.companies);

  const handleClick = (company: CompanySelected) => {
    dispatch(switchCompany(company));
    return navigate('/home');
  };

  return (
    <Container>
      <TitlePage title="Empresas" />
      <Context>
        {companies?.map((company: CompanyType) => (
          <Card
            key={company.cnpj}
            onClick={() =>
              handleClick({
                // eslint-disable-next-line no-underscore-dangle
                _id: company._id,
                cnpj: company.cnpj,
                name: company.name,
                id_group: company.id_group,
              })
            }
            sm={3}
          >
            <IconCompany />
            <TitleCard>{company.name}</TitleCard>
            <DescriptionCard>{company.cnpj}</DescriptionCard>
          </Card>
        ))}
      </Context>
    </Container>
  );
};

export default Main;
