import {
  all,
  call,
  put,
  takeLatest,
  CallEffect,
  PutEffect,
} from 'redux-saga/effects';

import authService from '../../../services/Auth.service';
import FormattedResponseType from '../../../types/formattedResponse.type';
import UserType from '../../../types/user.type';
import CompanyType from '../../../types/company.type';

import { signInRequest, signInSuccess, signFailure } from './SignIn.slice';
import { companySuccess } from '../Company/Company.slice';

import api from '../../../services/api';

export function* signIn({
  payload,
}: ReturnType<typeof signInRequest>): Generator<
  | CallEffect<FormattedResponseType>
  | PutEffect<{
      payload: UserType;
      type: string;
    }>
  | PutEffect<{
      payload: CompanyType[];
      type: string;
    }>
  | PutEffect<{
      payload: string;
      type: string;
    }>,
  void,
  FormattedResponseType
> {
  try {
    const { email, password } = payload;

    const session: FormattedResponseType = yield call(
      authService.signIn,
      email,
      password,
    );

    if (session.status) {
      const { user, token, companies } = session.data;
      const userData = { token, ...user };

      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      yield put(companySuccess(companies));
      yield put(signInSuccess(userData));
    } else {
      const { msg } = session;
      yield put(signFailure(msg));
    }
  } catch (error) {
    yield put(signFailure(error.message));
  }
}

export default all([takeLatest(signInRequest.type, signIn)]);
