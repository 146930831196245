/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tab, Row, Col, Nav, Modal, Pagination } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { format, compareAsc, getDate, setDate } from 'date-fns';
import getTranslation from '../../../utils/getTranslation';
import isDate from '../../../utils/isDate';
import ptBR from '../../../utils/locale';

// import Logo from '../../assets/images/profile-exemplo.png';
import Logo from '../../../assets/svgs/profile.svg';
import Flag from '../../../Components/Flag';
import {
  getActivitiesCustomer,
  getChargesCustomer,
  isAModifiedCustomer,
  validationCustomerModified,
} from '../../../services/Customer.service';

import {
  Container,
  Context,
  NavGoBackContainer,
  NavGoBack,
  NavGoBackName,
  GoBackIcon,
  ProfileContainer,
  HeaderDetails,
  ImgProfile,
  CustomerName,
  LastLoginText,
  BodyDetails,
  Label,
  Text,
  RowDetail,
  TabsContainer,
  TabContent,
  Dot,
  HeaderTab,
  TitleTab,
  UserCheckIcon,
  ButtonStyled,
  BodyTab,
  RowForm,
  InputStyled,
  UserIcon,
  FlagIcon,
  FinancialIcon,
  StatusContainer,
  ContentStatusActive,
  ModalContent,
  ModalContainer,
  ModalRow,
  ModalBody,
  ModalSubTitle,
  ModalDivider,
  ModalRowForm,
  CounterNotification,
  LoginSpinner,
  TableStyled,
  THead,
  Th,
  Td,
  Tr,
  NavItem,
  NavLink,
  GroupButton,
  FinancialContainer,
  CardItemFinancial,
  ValueCard,
  TitleCard,
  DescriptionCard,
  ContentCard,
  GroupDiscriptionCard,
  AttentionListIcon,
  CheckListIcon,
  TimeListIcon,
  AlertTable,
  PaginationStyled,
  AreaPagination,
  DescriptionPagination,
} from './styles';

import { useAppSelector } from '../../../store';

interface EditionsProps {
  path: string;
  new_value: string;
  old_value: string;
}

interface ActivitesProps {
  id_customer: string;
  name_customer: string;
  cpf_customer: string;
  type_customer: string;
  date: string;
  origin: string;
}

interface FinancialProps {
  _id: string;
  chave: string;
  valor_titulo: number;
  vencto: string;
  status: string;
  forma_pagamento: string;
  contrato: string;
  statusColor?: string;
}

interface FinancialTotalizersProps {
  title: string;
  value: string;
  description: string;
  status: string;
}

const CustomersDetails: React.FC = () => {
  const navigate = useNavigate();

  const ACTIVITIES_PER_PAGE = 8;
  const CHARGES_PER_PAGE = 8;
  const HOME_PAGE = 1;

  const [pageInit, setPageInit] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageInitCharges, setPageInitCharges] = useState(0);
  const [pageLimitCharges, setPageLimitCharges] = useState(5);
  const [descriptionPaginationActivities, setDescriptionPaginationActivities] =
    useState('');
  const [descriptionPaginationCharges, setDescriptionPaginationCharges] =
    useState('');
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [editions, setEditions] = useState<EditionsProps[]>([]);
  const [activities, setActivities] = useState<ActivitesProps[]>([]);
  const [financial, setFinancial] = useState<FinancialProps[]>([]);
  const [financialTotal, setFinancialTotal] = useState<FinancialProps[]>([]);
  const [financialTotalizers, setFinancialTotalizers] = useState<
    FinancialTotalizersProps[]
  >([]);
  const [loadingApproved, setLoadingApproved] = useState(false);
  const [loadingDisapproved, setLoadingDisapproved] = useState(false);
  const [inListing, setInListing] = useState(true);
  const [selectedPageActivities, setSelectedPageActivities] =
    useState<number>(HOME_PAGE);
  const [selectedPageCharges, setSelectedPageCharges] =
    useState<number>(HOME_PAGE);
  const [paginationItemActivities, setPaginationItemActivities] = useState<
    number[]
  >([]);
  const [paginationItemCharges, setPaginationItemCharges] = useState<number[]>(
    [],
  );
  const [totalActivities, setTotalActivities] = useState<number>(0);
  const [totalCharges, setTotalCharges] = useState<number>(0);

  const customer = useAppSelector(state => state.customerReducer?.customer);
  const user = useAppSelector(state => state.signInReducer?.user);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleGoBack = () => {
    navigate('/customers/customers-list');
  };

  const handleChangeListingFinancial = () => {
    return setInListing(() => !inListing);
  };

  const formatDate = (date: string) => {
    if (!isDate(date)) return '';

    let newDate = new Date(
      date.indexOf('T') !== -1 ? date.split('T')[0] : date,
    );

    newDate = setDate(newDate, getDate(newDate) + 1);

    return format(newDate, "dd 'de' MMMM", {
      locale: ptBR,
    });
  };

  const formatDateWithYear = (date: string) => {
    if (!isDate(date)) return '';

    let newDate = new Date(
      date.indexOf('T') !== -1 ? date.split('T')[0] : date,
    );

    newDate = setDate(newDate, getDate(newDate) + 1);

    return format(newDate, 'dd/MM/yyyy', {
      locale: ptBR,
    });
  };

  const isExpiredDate = (date: string) => {
    const compareDate = compareAsc(new Date(date), new Date());

    return compareDate === 1;
  };

  const formatDateWithTime = (date: string) => {
    if (!isDate(date)) return '';

    let newDate = new Date(
      date.indexOf('T') !== -1 ? date.split('T')[0] : date,
    );

    newDate = setDate(newDate, getDate(newDate) + 1);

    return format(newDate, 'dd/MM/yyyy HH:mm', {
      locale: ptBR,
    });
  };

  const hideCpf = (cpf: string) => {
    if (!cpf) return '';
    const cpfArr = cpf.split('');

    return `${cpfArr.splice(0, 3).join('')}.${cpfArr
      .splice(0, 3)
      .join('')}.******`;
  };

  const formatCpf = (cpf: string) => {
    if (!cpf) return '';
    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(
      6,
      9,
    )}-${cpf.slice(9, cpf.length)}`;
  };

  const formatValuer = (number: number) => {
    if (!number) return '';
    return `R$ ${number.toFixed(2)}`;
  };

  const formatTelephone = (telephone: string) => {
    if (!telephone) return '';
    return `(${telephone.slice(0, 2)}) ${telephone.slice(
      2,
      telephone.length - 4,
    )}-${telephone.slice(telephone.length - 4, telephone.length)}`;
  };

  const getActivities = useCallback(
    async (limit: number, page: number, customerId: string) => {
      const { data, status, msg } = await getActivitiesCustomer(
        customerId,
        limit,
        page,
      );

      if (!status) toast.error(msg);
      else {
        setActivities(
          data?.accessReport
            .map((activity: ActivitesProps) => {
              const activityTmp = activity;

              activityTmp.date = formatDateWithTime(activity.date);

              return activityTmp;
            })
            .reverse((activity: ActivitesProps) => activity.date),
        );
        setTotalActivities(data?.totalDocs);

        const countItem = [];
        for (
          let num = 1;
          num <= Math.ceil(data.totalDocs / ACTIVITIES_PER_PAGE);
          num += 1
        ) {
          countItem.push(num);
        }

        setPaginationItemActivities(countItem);
        // setItemActivities(countItem.slice(paginationPrev, paginationNext));
      }
    },
    [],
  );

  const sumValuesReduce = (previousValue: number, currentValue: number) => {
    return previousValue + currentValue;
  };

  const setFinancialTotalizersData = useCallback((data: any) => {
    const totalizers = [];
    const totalOpen: number[] = [];
    const valueTotalOpen: number[] = [];
    const totalPaid: number[] = [];
    const valueTotalPaid: number[] = [];

    // eslint-disable-next-line array-callback-return
    data?.contratos.map((contracts: any) => {
      if (contracts.titulos_abertos && contracts.titulos_abertos.length) {
        const total = contracts.titulos_abertos.map(
          (open: any) => open.valor_titulo,
        );
        totalOpen.push(total.length);
        valueTotalOpen.push(total.reduce(sumValuesReduce));
      }

      if (contracts.titulos_pagos && contracts.titulos_pagos.length) {
        const total = contracts.titulos_pagos.map(
          (paid: any) => paid.valor_titulo,
        );
        totalPaid.push(total.length);
        valueTotalPaid.push(total.reduce(sumValuesReduce));
      }
    });

    if (totalOpen.length) {
      totalizers.push({
        title: 'Em Aberto',
        description: `${totalOpen.reduce(sumValuesReduce)} títulos`,
        value: `R$ ${valueTotalOpen.reduce(sumValuesReduce).toFixed(2)}`,
        status: 'success',
      });
    }

    if (totalPaid.length) {
      totalizers.push({
        title: 'Baixado',
        description: `${totalPaid.reduce(sumValuesReduce)} títulos`,
        value: `R$ ${valueTotalPaid.reduce(sumValuesReduce).toFixed(2)}`,
        status: 'warning',
      });
    }

    if (data.processando && data.processando.length) {
      const total = data?.processando.map(
        (process: any) => process.valor_titulo,
      );
      const valueTotal = total.reduce(sumValuesReduce);

      totalizers.push({
        title: 'Em Processamento',
        description: `${total.length} títulos`,
        value: `R$ ${valueTotal.toFixed(2)}`,
        status: 'attention',
      });
    }

    setFinancialTotalizers(totalizers);
  }, []);

  const getCharges = useCallback(
    async (cpf: string, id_group: string) => {
      const { data, status, msg } = await getChargesCustomer(id_group, cpf);

      if (!status) toast.error(msg);
      else if (Object.keys(data).length) {
        const charges: any = [];
        data?.contratos.map((contracts: any) => {
          contracts.titulos_abertos.forEach((open: any) => {
            const openTmp = open;
            if (isExpiredDate(open.vencto)) {
              openTmp.status = 'A vencer';
              openTmp.statusColor = 'warning';
            } else {
              openTmp.status = 'Vencido';
              openTmp.statusColor = 'error';
            }
            charges.push(openTmp);
          });
          return contracts.titulos_pagos.forEach((paid: any) => {
            const paidTmp = paid;
            paidTmp.status = 'Baixado';
            paidTmp.statusColor = 'success';
            charges.push(paidTmp);
          });
        });

        const processing: any = [];

        if (data?.processando.length) {
          data.processando.forEach((process: any) => {
            const processTmp = process;

            processTmp.status = 'Em Processamento';
            processTmp.statusColor = 'warning';

            processing.push(processTmp);
          });
        }

        const chargesData = [...charges, ...processing];

        setFinancial(chargesData.slice(0, CHARGES_PER_PAGE));
        setFinancialTotal(chargesData);
        setTotalCharges(chargesData.length);

        const countItem = [];
        for (
          let num = 1;
          num <= Math.ceil(chargesData.length / CHARGES_PER_PAGE);
          num += 1
        ) {
          countItem.push(num);
        }

        setPaginationItemCharges(countItem);
        setFinancialTotalizersData(data);
      }
    },
    [setFinancialTotalizersData],
  );

  const changePage = (page: number) => {
    if (page > 0 && page <= paginationItemActivities.length) {
      if (page > 5) {
        setPageInit(page - 5);
        setPageLimit(page);
      } else {
        setPageInit(0);
        setPageLimit(5);
      }
      setSelectedPageActivities(page);
      getActivities(ACTIVITIES_PER_PAGE, page, customer.id_customer);
    }
  };

  const changePageCharges = (page: number) => {
    if (page > 0 && page <= paginationItemCharges.length) {
      if (page > 5) {
        setPageInitCharges(page - 5);
        setPageLimitCharges(page);
      } else {
        setPageInitCharges(0);
        setPageLimitCharges(5);
      }

      setSelectedPageCharges(page);

      const limit = page * CHARGES_PER_PAGE;
      const init = limit - CHARGES_PER_PAGE;
      setFinancial(financialTotal.slice(init < 0 ? 0 : init, limit));
    }
  };

  const switchIconFinancial = (status: string) => {
    switch (status) {
      case 'success':
        return <CheckListIcon />;
      case 'warning':
        return <TimeListIcon />;
      case 'attention':
        return <AttentionListIcon />;
      default:
        return <TimeListIcon />;
    }
  };

  function isEditions(edition: EditionsProps, path: string) {
    return edition.path === path;
  }

  const isModifiedCustomerData = useCallback(async () => {
    const { data, status, msg } = await isAModifiedCustomer(
      customer.id_customer,
    );

    if (!status) toast.error(msg);
    else if (data && Object.keys(data).length) {
      setPhone(data.phone);
      setMail(data.email);

      if (!data.editions && !data.editions.length) return;
      const editionsTmp: EditionsProps[] = [];

      data.editions.map((edition: EditionsProps) => {
        const paths = data.editions.filter(
          (edit: EditionsProps) => edit.path === edition.path,
        );

        if (
          !editionsTmp.find((edit: EditionsProps) =>
            isEditions(edit, paths[paths.length - 1].path),
          )
        )
          editionsTmp.push(paths[paths.length - 1]);
        return true;
      });

      setEditions(editionsTmp);
    } else setEditions([]);
  }, [customer.id_customer]);

  useEffect(() => {
    isModifiedCustomerData();
    getActivities(ACTIVITIES_PER_PAGE, HOME_PAGE, customer.id_customer);
    getCharges(customer.username, customer.id_group);
  }, [customer, getActivities, getCharges, isModifiedCustomerData]);

  const validationCustomer = async (statusValidation: string) => {
    if (statusValidation === 'approved') setLoadingApproved(true);
    else setLoadingDisapproved(true);

    const { status, msg } = await validationCustomerModified(
      statusValidation,
      customer.id_customer,
      user.id_profile,
    );

    setLoadingApproved(false);
    setLoadingDisapproved(false);

    if (!status) toast.error(msg);
    else {
      if (statusValidation === 'approved') {
        toast.success('Dados atualizados com sucesso!');
      } else toast.success('Dados rejeitados');
      handleClose();
      isModifiedCustomerData();
    }
  };

  useEffect(() => {
    setDescriptionPaginationActivities(
      `${
        (selectedPageActivities - 1) * ACTIVITIES_PER_PAGE +
        activities.length -
        (activities.length - 1)
      }-${
        (selectedPageActivities - 1) * ACTIVITIES_PER_PAGE + activities.length
      } de ${totalActivities} Atividades`,
    );
  }, [activities.length, selectedPageActivities, totalActivities]);

  useEffect(() => {
    setDescriptionPaginationCharges(
      `${
        (selectedPageCharges - 1) * CHARGES_PER_PAGE +
        financial.length -
        (financial.length - 1)
      }-${
        (selectedPageCharges - 1) * CHARGES_PER_PAGE + financial.length
      } de ${totalCharges} Títulos`,
    );
  }, [financial.length, selectedPageCharges, totalCharges]);

  return (
    <Container>
      <NavGoBackContainer onClick={handleGoBack}>
        <GoBackIcon />
        <NavGoBack>Lista</NavGoBack>
        <Dot />
        <NavGoBackName>{customer.name}</NavGoBackName>
      </NavGoBackContainer>
      <Context>
        <ProfileContainer>
          <HeaderDetails>
            <ImgProfile src={Logo} />
            <CustomerName>{customer.name}</CustomerName>
            <LastLoginText>{formatDate(customer.last_login)}</LastLoginText>
          </HeaderDetails>
          <BodyDetails>
            <RowDetail>
              <Label>CPF</Label>
              <Text>{formatCpf(customer.username)}</Text>
            </RowDetail>
            <RowDetail>
              <Label>Tipo</Label>
              <Text>{getTranslation(customer.type)}</Text>
            </RowDetail>
            <RowDetail>
              <Label>Criado em</Label>
              <Text>{formatDate(customer.created_at)}</Text>
            </RowDetail>
            <RowDetail>
              <Label>Origem</Label>
              <Text>{customer.origin}</Text>
            </RowDetail>
            <RowDetail>
              <Label>Último Login</Label>
              <Text>{formatDate(customer.last_login)}</Text>
            </RowDetail>
            <RowDetail>
              <Label>Avaliação</Label>
              <Text>Sem avaliação</Text>
            </RowDetail>
            <StatusContainer>
              <Label>Status</Label>
              <ContentStatusActive status={editions && editions.length > 0}>
                {editions && editions.length > 0 ? 'Pendente' : 'Ativo'}
              </ContentStatusActive>
            </StatusContainer>
          </BodyDetails>
        </ProfileContainer>
        <TabsContainer>
          <Tab.Container defaultActiveKey="profile">
            <Col>
              <Row>
                <Nav variant="pills" className="flex-row">
                  <NavItem style={{ marginLeft: '0.4rem' }}>
                    <NavLink eventKey="profile">
                      <UserIcon /> Perfil
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey="activities">
                      <FlagIcon /> Atividades
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey="financial">
                      <FinancialIcon /> Financeiro
                    </NavLink>
                  </NavItem>
                </Nav>
              </Row>
              <Row>
                <TabContent>
                  <Tab.Pane eventKey="profile">
                    <HeaderTab>
                      <TitleTab>Perfil</TitleTab>
                      <ButtonStyled disabled={!editions || !editions.length}>
                        <UserCheckIcon onClick={handleShow} />
                        {editions && editions.length > 0 && (
                          <CounterNotification>
                            {editions.length}
                          </CounterNotification>
                        )}
                      </ButtonStyled>
                    </HeaderTab>
                    <BodyTab>
                      <RowForm>
                        <Label>Nome</Label>
                        <InputStyled
                          name="name"
                          type="text"
                          value={customer.name.split(' ')[0]}
                          disabled
                        />
                      </RowForm>
                      <RowForm>
                        <Label>Sobrenome</Label>
                        <InputStyled
                          name="last-name"
                          type="text"
                          value={customer.name
                            .split(' ')
                            .splice(1, customer.name.split(' ').length)
                            .join(' ')}
                          disabled
                        />
                      </RowForm>
                      <RowForm>
                        <Label>Data de nascimento</Label>
                        <InputStyled
                          name="birth-date"
                          type="text"
                          value={formatDateWithYear(customer.birth)}
                          disabled
                        />
                      </RowForm>
                      <RowForm>
                        <Label>Telefone</Label>
                        <InputStyled
                          name="phone"
                          type="text"
                          value={formatTelephone(phone)}
                          disabled
                        />
                      </RowForm>
                      <RowForm>
                        <Label>E-mail</Label>
                        <InputStyled
                          name="mail"
                          type="text"
                          value={mail}
                          disabled
                        />
                      </RowForm>
                    </BodyTab>
                  </Tab.Pane>
                  <Tab.Pane eventKey="activities">
                    <HeaderTab>
                      <TitleTab>Atividades</TitleTab>
                    </HeaderTab>
                    <BodyTab>
                      <TableStyled hover>
                        <THead>
                          <tr>
                            <Th>Data e tempo</Th>
                            <Th>Nome</Th>
                            <Th>CPF</Th>
                            <Th>Tipo</Th>
                          </tr>
                        </THead>
                        <tbody>
                          {activities.map(
                            (activity: ActivitesProps, key: number) => (
                              <Tr key={activity.date + String(Math.random())}>
                                <Td>{activity.date}</Td>
                                <Td>{activity.name_customer}</Td>
                                <Td>
                                  <Flag text={hideCpf(activity.cpf_customer)} />
                                </Td>
                                <Td>
                                  {getTranslation(activity.type_customer)}
                                </Td>
                              </Tr>
                            ),
                          )}
                        </tbody>
                        {paginationItemActivities.length > 0 && (
                          <tfoot>
                            <tr>
                              <td colSpan={5}>
                                <AreaPagination>
                                  <DescriptionPagination
                                    style={{ visibility: 'hidden' }}
                                  >
                                    {descriptionPaginationActivities}
                                  </DescriptionPagination>
                                  <PaginationStyled size="sm">
                                    <Pagination.First
                                      onClick={() => changePage(HOME_PAGE)}
                                    />
                                    <Pagination.Prev
                                      onClick={() =>
                                        changePage(selectedPageActivities - 1)
                                      }
                                    />
                                    {paginationItemActivities
                                      .slice(pageInit, pageLimit)
                                      .map((page: number) => (
                                        <Pagination.Item
                                          key={page}
                                          active={
                                            page === selectedPageActivities
                                          }
                                          onClick={() => changePage(page)}
                                        >
                                          {page}
                                        </Pagination.Item>
                                      ))}
                                    <Pagination.Next
                                      onClick={() =>
                                        changePage(selectedPageActivities + 1)
                                      }
                                    />
                                    <Pagination.Last
                                      onClick={() =>
                                        changePage(
                                          paginationItemActivities.length,
                                        )
                                      }
                                    />
                                  </PaginationStyled>
                                  <DescriptionPagination>
                                    {descriptionPaginationActivities}
                                  </DescriptionPagination>
                                </AreaPagination>
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </TableStyled>
                      {totalActivities <= 0 && (
                        <AlertTable>Nenhuma atividade registrada</AlertTable>
                      )}
                    </BodyTab>
                  </Tab.Pane>
                  <Tab.Pane eventKey="financial">
                    <HeaderTab>
                      <TitleTab>Financeiro</TitleTab>
                      <GroupButton>
                        <ButtonStyled
                          onClick={handleChangeListingFinancial}
                          variant={inListing ? 'primary' : 'secondary'}
                        >
                          Lista
                        </ButtonStyled>
                        {totalCharges > 0 && (
                          <ButtonStyled
                            style={{ marginLeft: '0.5rem' }}
                            onClick={handleChangeListingFinancial}
                            variant={!inListing ? 'primary' : 'secondary'}
                          >
                            Resumo
                          </ButtonStyled>
                        )}
                      </GroupButton>
                    </HeaderTab>
                    <BodyTab>
                      {inListing && (
                        <>
                          <TableStyled hover>
                            <THead>
                              <tr>
                                <Th>Chave de título</Th>
                                <Th>Valor</Th>
                                <Th>Vencimento</Th>
                                <Th>Situação</Th>
                              </tr>
                            </THead>
                            <tbody>
                              {financial.map((fin: FinancialProps) => (
                                <Tr key={fin._id}>
                                  <Td>{fin.chave}</Td>
                                  <Td>{formatValuer(fin.valor_titulo)}</Td>
                                  <Td>{formatDateWithYear(fin.vencto)}</Td>
                                  <Td>
                                    <Flag
                                      text={fin.status}
                                      status={fin.statusColor}
                                    />
                                  </Td>
                                </Tr>
                              ))}
                            </tbody>
                            {paginationItemCharges.length > 0 && (
                              <tfoot>
                                <tr>
                                  <td colSpan={5}>
                                    <AreaPagination>
                                      <DescriptionPagination
                                        style={{ visibility: 'hidden' }}
                                      >
                                        {descriptionPaginationCharges}
                                      </DescriptionPagination>
                                      <PaginationStyled size="sm">
                                        <Pagination.First
                                          onClick={() =>
                                            changePageCharges(HOME_PAGE)
                                          }
                                        />
                                        <Pagination.Prev
                                          onClick={() =>
                                            changePageCharges(
                                              selectedPageCharges - 1,
                                            )
                                          }
                                        />
                                        {paginationItemCharges
                                          .slice(
                                            pageInitCharges,
                                            pageLimitCharges,
                                          )
                                          .map((page: number) => (
                                            <Pagination.Item
                                              key={page}
                                              active={
                                                page === selectedPageCharges
                                              }
                                              onClick={() =>
                                                changePageCharges(page)
                                              }
                                            >
                                              {page}
                                            </Pagination.Item>
                                          ))}
                                        <Pagination.Next
                                          onClick={() =>
                                            changePageCharges(
                                              selectedPageCharges + 1,
                                            )
                                          }
                                        />
                                        <Pagination.Last
                                          onClick={() =>
                                            changePageCharges(
                                              paginationItemCharges.length,
                                            )
                                          }
                                        />
                                      </PaginationStyled>
                                      <DescriptionPagination>
                                        {descriptionPaginationCharges}
                                      </DescriptionPagination>
                                    </AreaPagination>
                                  </td>
                                </tr>
                              </tfoot>
                            )}
                          </TableStyled>
                          {totalCharges <= 0 && (
                            <AlertTable>Nenhum título encontrado</AlertTable>
                          )}
                        </>
                      )}
                      {!inListing && (
                        <FinancialContainer>
                          {financialTotalizers.map(
                            (total: FinancialTotalizersProps) => (
                              <CardItemFinancial
                                status={total.status}
                                key={total.value}
                              >
                                <ContentCard>
                                  {switchIconFinancial(total.status)}
                                  <GroupDiscriptionCard>
                                    <TitleCard>{total.title}</TitleCard>
                                    <ValueCard>{total.value}</ValueCard>
                                  </GroupDiscriptionCard>
                                </ContentCard>
                                <DescriptionCard status={total.status}>
                                  {total.description}
                                </DescriptionCard>
                              </CardItemFinancial>
                            ),
                          )}
                        </FinancialContainer>
                      )}
                    </BodyTab>
                  </Tab.Pane>
                </TabContent>
              </Row>
            </Col>
          </Tab.Container>
        </TabsContainer>
        <ModalContent show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Alterações</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ModalContainer>
              <ModalRow>
                <ModalBody>
                  <ModalSubTitle>Atual</ModalSubTitle>
                </ModalBody>
                <ModalDivider style={{ height: '3.1rem' }} />
                <ModalBody>
                  <ModalSubTitle>Solicitação</ModalSubTitle>
                </ModalBody>
              </ModalRow>
              {editions.map((edition: EditionsProps) => (
                <ModalRow>
                  <ModalBody>
                    <ModalRowForm>
                      <Label>{getTranslation(edition.path)}</Label>
                      <InputStyled
                        name={edition.path}
                        type="text"
                        value={edition.old_value}
                        disabled
                      />
                    </ModalRowForm>
                  </ModalBody>
                  <ModalDivider />
                  <ModalBody>
                    <ModalRowForm>
                      <Label>{getTranslation(edition.path)}</Label>
                      <InputStyled
                        name={edition.path}
                        type="text"
                        value={edition.new_value}
                        disabled
                      />
                    </ModalRowForm>
                  </ModalBody>
                </ModalRow>
              ))}
            </ModalContainer>
          </Modal.Body>

          <Modal.Footer>
            <ButtonStyled
              variant="danger"
              disabled={loadingDisapproved}
              onClick={() => validationCustomer('disapproved')}
            >
              {loadingDisapproved ? (
                <LoginSpinner animation="border" variant="light" />
              ) : (
                'Rejeitar'
              )}
            </ButtonStyled>
            <ButtonStyled
              variant="primary"
              disabled={loadingApproved}
              onClick={() => validationCustomer('approved')}
            >
              {loadingApproved ? (
                <LoginSpinner animation="border" variant="light" />
              ) : (
                'Aprovar'
              )}
            </ButtonStyled>
          </Modal.Footer>
        </ModalContent>
      </Context>
    </Container>
  );
};

export default CustomersDetails;
