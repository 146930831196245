import FormattedResponseType from '../types/formattedResponse.type';
import AdvancedFilterType from '../types/partnerAdvancedFilter.type';
import PartnersType from '../types/partners.type';
import Api from './api';

export const getPartners = async (
  groupId: string,
  limit: number,
  page: number,
  data?: AdvancedFilterType,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    if (data && Object.keys(data).length) {
      response = await Api.post(
        `partner/${groupId}/${page}/${limit}/list`,
        data,
      );
    } else {
      response = await Api.post(`partner/${groupId}/${page}/${limit}/list`);
    }

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const updatePartner = async (
  partnerId: string,
  data: PartnersType,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.put(`partner/${partnerId}/update`, data);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const createPartner = async (
  data: PartnersType,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.post(`partner/create`, data);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const deletePartner = async (
  partnerId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.delete(`partner/${partnerId}/delete`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const getPartner = async (
  partnerId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.get(`partner/${partnerId}/show`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const getPartnerCategory = async (
  groupId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.get(`partnercategory/${groupId}/list`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const getDashboardPartners = async (
  groupId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.get(`dashboard/${groupId}/partners`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};
