import styled from 'styled-components';
import { Button, Spinner, ButtonGroup, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { theme } from '../../../../global/styles/theme';
import Input from '../../../../Components/Input';

import { ReactComponent as ArrowLeftIcon } from '../../../../assets/svgs/arrow-left.svg';
import { ReactComponent as HelpIcon } from '../../../../assets/svgs/help.svg';
import { ReactComponent as InputPlay } from '../../../../assets/svgs/play.svg';

const { colors } = theme;

export const Container = styled.div`
  width: 100%;
  margin: 4.5rem auto;
  padding-left: 8rem;
  padding-right: 1.6rem;
`;
export const ContainerBootstrap = styled.div`
  max-width: 1200px !important;
  padding: 0px;
`;

export const Context = styled.div`
  display: flex;
  width: 100%;
  margin: 2rem auto;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 1.2rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const NavGoBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const NavGoBack = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 0;
  color: ${colors.text.tHead};
`;

export const LabelId = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 0;
  color: ${colors.text.tHead};
`;

export const NavGoBackName = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  color: ${colors.text.title};
`;

export const GoBackIcon = styled(ArrowLeftIcon)`
  margin-right: 1rem;
`;

export const HelpIconStyled = styled(HelpIcon)`
  margin-left: 0.5rem;
  width: auto;
  height: 0.8rem;
`;

export const Dot = styled.div`
  border-radius: 50%;
  background-color: ${colors.text.tHead};
  height: 6px;
  width: 6px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

export const RowForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  width: 100%;
  margin-bottom: 2rem;
`;
export const IconPlay = styled(InputPlay)`
  width: 1rem;
  height: 100%;
  margin-right: 0.5rem;
  margin-top: -2px;
`;
export const ButtonFilter = styled(Button)`
  margin-left: 0.6rem;
  border-radius: 10px;

  @media (max-width: 550px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
`;
export const InputStyled = styled(Input)`
  max-width: 44rem;
  width: 100%;
  margin-top: 0.6rem;
`;

export const Textarea = styled(Input)`
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
  min-height: 2.4rem;
  background-color: ${colors.white};
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: ${colors.text.title};
  font-weight: 400;
  font-size: 13px;
  border: 1px solid ${colors.borderInput};
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 44rem;
  width: 100%;
  margin-top: 0.6rem;
  padding: 0.5rem;
`;

export const Select = styled(Form.Select)`
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
  min-height: 2.4rem;
  background-color: ${colors.white};
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: ${colors.text.title};
  font-weight: 400;
  font-size: 13px;
  border: 1px solid ${colors.borderInput};
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 44rem;
  width: 100%;
  margin-top: 0.6rem;
`;

export const ButtonGroupStyled = styled(ButtonGroup)`
  margin-top: 0.6rem;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: block;

  @media (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
`;

export const ButtonQtd = styled(Button)`
  margin-left: 0.6rem;
  border-radius: 10px;

  @media (max-width: 550px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
`;

export const ButtonStyled = styled(Button)`
  margin-left: 0.6rem;
  border-radius: 10px;

  @media (max-width: 550px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
`;

export const LoginSpinner = styled(Spinner)`
  height: 1.4rem;
  width: 1.4rem;
`;

export const GroupButton = styled.div`
  margin-top: 0.6rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const DatePickerStyled = styled(DatePicker)`
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
  max-width: 44rem;
  width: 100%;
  min-height: 2.4rem;
  background-color: ${colors.white};
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: ${colors.text.title};
  font-weight: 400;
  font-size: 13px;
  border: 1px solid ${colors.borderInput};
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 0.6rem;

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::placeholder,
  &:-ms-input-placeholder {
    color: ${colors.background};
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border: 1px solid ${colors.primary};
    box-shadow: none;
  }
`;
