export const theme = {
  colors: {
    primary: '#9B6CC6',
    background: '#f6f8fa',
    backgroundPrimary: '#EDD9FF',
    white: '#ffffff',
    green: '#25cc86',
    borderInput: '#bebebe',
    text: {
      title: '#333333',
      tHead: '#8c8c8c',
    },
    flag: {
      success: {
        primary: '#25cc86',
        background: '#e5f9f0',
      },
      warning: {
        primary: '#debc45',
        background: '#ffedac',
      },
      error: {
        primary: '#f05d48',
        background: '#ffe4e0',
      },
      grey: {
        primary: '#515365',
        background: '#e0e0e0',
      },
      blue: {
        primary: '#2196f3',
        background: '#e7f7ff',
      },
    },
  },
};
