import React from 'react';
import ReactIcon from 'react-icons';
import { FormControlProps } from 'react-bootstrap';

import { Container, Input } from './styles';

interface InputProps extends FormControlProps {
  name: string;
  icon?: React.ComponentType<ReactIcon.IconBaseProps>;
}

const defaultProps = {
  icon: undefined,
};

const InputOutlined: React.FC<InputProps> = ({ icon: Icon, ...rest }) => {
  return (
    <Container>
      {Icon && <Icon size={30} />}
      <Input {...rest} />
    </Container>
  );
};

InputOutlined.defaultProps = defaultProps;

export default InputOutlined;
