import styled from 'styled-components';
import { ReactComponent as InputQuestion } from '../../assets/svgs/question.svg';

export const QuestionStyled = styled.div`
  cursor: pointer;
  padding: 0.5rem 0rem 0rem 0.3rem;
`;
export const IconQuestion = styled(InputQuestion)`
  width: 100%;
  height: 100%;
  max-width: 22px;
  max-height: 22px;
`;
