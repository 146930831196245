import axios from 'axios';

const ApiImage = axios.create({
  baseURL: 'https://images.plataformavirtus.com.br/',
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default ApiImage;
