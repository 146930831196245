import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { theme } from '../../global/styles/theme';

export const Container = styled.div`
  display: flex;

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const Input = styled(InputMask)`
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
  max-width: 14rem;
  width: 100%;
  min-height: 2.4rem;
  background-color: ${theme.colors.white};
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: ${theme.colors.text.title};
  font-weight: 400;
  font-size: 13px;
  border: 1px solid ${theme.colors.borderInput};
  box-sizing: border-box;
  border-radius: 8px;

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::placeholder,
  &:-ms-input-placeholder {
    color: ${theme.colors.background};
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border: 1px solid ${theme.colors.primary};
    box-shadow: none;
  }
`;
