import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import pt from '../../utils/locale';
import SkeletonList from '../../Components/SkeletonList';
import TitlePage from '../../Components/TitlePage';
import Flag from '../../Components/Flag';

import { ReactComponent as SearchIcon } from '../../assets/svgs/search.svg';

import {
  Container,
  Context,
  Header,
  ButtonFilter,
  InputSearchStyled,
  PaginationStyled,
  TableStyled,
  THead,
  Th,
  Td,
  Tr,
  ContextFilter,
  FormGroup,
  Label,
  GroupButton,
  ButtonStyled,
  FooterFilter,
  HeaderFilter,
  ArrowDownIconStyled,
  ArrowTopIconStyled,
  DatePickerStyled,
  LoginSpinner,
  AlertTable,
  AreaPagination,
  DescriptionPagination,
} from './styles';

import { useAppDispatch, useAppSelector } from '../../store';
import { selectCustomer } from '../../store/slices/Customer/Customer.slice';

import { getCustomers } from '../../services/Customer.service';

import CustomerType from '../../types/customer.type';
import FilterType from '../../types/customerAdvancedFilter.type';
import getTranslation from '../../utils/getTranslation';

import 'react-datepicker/dist/react-datepicker.css';

const Customers: React.FC = () => {
  const CUSTOMERS_PER_PAGE = 15;
  const HOME_PAGE = 1;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { state }: { state: any } = useLocation();
  const { filtered } = state || {
    filtered: false,
  };

  const [search, setSearch] = useState('');
  const [descriptionPagination, setDescriptionPagination] = useState('');
  const [pageInit, setPageInit] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [customers, setCustomers] = useState<CustomerType[]>([]);
  const [paginationItem, setPaginationItem] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(HOME_PAGE);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCustomers, setTotalCustomers] = useState<number>(0);
  const [loadingClean, setLoadingClean] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState(false);
  const [filterStatus, setFilterStatus] = useState<boolean | string>('init');
  const [filterOrigin, setFilterOrigin] = useState<boolean | string>('init');
  const [filterType, setFilterType] = useState<boolean | string>('init');
  const [birth, setBirth] = useState<[Date | null, Date | null]>([null, null]);
  const [lastLogin, setLastLogin] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [creationDate, setCreationDate] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startBirth, endBirth] = birth;
  const [startLastLoginDate, endLastLoginDate] = lastLogin;
  const [startCreationDate, endCreationDate] = creationDate;

  const companies = useAppSelector(
    stateCompnay => stateCompnay.companyReducer.companySelected,
  );

  const hideCpf = (cpf: string) => {
    const cpfArr = cpf.split('');

    return `${cpfArr.splice(0, 3).join('')}.${cpfArr
      .splice(0, 3)
      .join('')}.******`;
  };

  const getCustomersData = useCallback(
    async (limit: number, page: number, filter?: FilterType) => {
      const { data, status, msg } = await getCustomers(
        companies.id_group,
        limit,
        page,
        filter || undefined,
      );

      if (!status) {
        toast.error(msg);
        setLoading(false);
        setLoadingClean(false);
      } else {
        const customersTmp = data?.customers;
        const totalDocs = data?.totalDocs;

        setCustomers(data?.customers);
        setTotalCustomers(totalDocs);

        const countItem = [];
        for (
          let num = 1;
          num <= Math.ceil(totalDocs / CUSTOMERS_PER_PAGE);
          num += 1
        ) {
          countItem.push(num);
        }

        if (countItem.length <= 0 && customersTmp.length > 0) {
          setPaginationItem([1]);
        } else setPaginationItem(countItem);
        setLoading(false);
        setLoadingClean(false);
      }
    },
    [companies.id_group],
  );

  const findValue = (value: string, find: string) => {
    return value.toLowerCase().indexOf(find.toLowerCase()) !== -1;
  };

  const formatDate = (date: Date) => {
    return format(date, 'yyyy-MM-dd', {
      locale: pt,
    });
  };

  // const filterCustomers = (value: string) => {
  //   setSearch(value);

  //   if (!value) return getCustomersData(CUSTOMERS_PER_PAGE, HOME_PAGE);

  //   const data = allCustomers.filter((customer: CustomerType) => {
  //     if (!customer.name) return false;

  //     const name = findValue(customer.name, value);
  //     const cpf = findValue(customer.username, value);
  //     // const origin = findValue(customer.origin, value);
  //     // const type = findValue(customer.type, value);

  //     return name || cpf;
  //   });

  //   if (!data.length) return setCustomers([]);

  //   setCustomers(data);

  //   return setPaginationItem([HOME_PAGE]);
  // };

  const customerAdvancedFilter = (limit: number, page: number) => {
    if (!loadingClean) setLoading(true);

    const [getOrigin] = customers.filter((customer: CustomerType) =>
      findValue(customer.origin, 'app'),
    );

    const originStandard = getOrigin ? getOrigin.origin : 'appcustomer';

    const data: FilterType = {};

    if (filterStatus !== 'init')
      data.editionsStatus = !filterStatus ? 'pending' : 'active';
    if (filterOrigin !== 'init')
      data.origin = filterOrigin ? originStandard : 'portal';
    if (filterType !== 'init') data.type = filterType ? 'dependent' : 'holder';

    if (birth[0] && birth[1]) {
      data.birth = {
        initial: formatDate(birth[0]),
        final: formatDate(birth[1]),
      };
    }
    if (lastLogin[0] && lastLogin[1]) {
      data.last_login = {
        initial: formatDate(lastLogin[0]),
        final: formatDate(lastLogin[1]),
      };
    }
    if (creationDate[0] && creationDate[1]) {
      data.created_at = {
        initial: formatDate(creationDate[0]),
        final: formatDate(creationDate[1]),
      };
    }

    if (search !== '') {
      data.name = search;
    }

    getCustomersData(limit, page, data);
  };

  const changePage = (page: number) => {
    if (page > 0 && page <= paginationItem.length) {
      if (page > 5) {
        setPageInit(page - 5);
        setPageLimit(page);
      } else {
        setPageInit(0);
        setPageLimit(5);
      }
      setSelectedPage(page);
      if (advancedFilter) customerAdvancedFilter(CUSTOMERS_PER_PAGE, page);
      else getCustomersData(CUSTOMERS_PER_PAGE, page);
    }
  };

  const cleanFilter = () => {
    setLoadingClean(true);
    setFilterStatus('init');
    setFilterOrigin('init');
    setFilterType('init');
    setLastLogin([null, null]);
    setBirth([null, null]);
    setCreationDate([null, null]);
    setSearch('');
    getCustomersData(CUSTOMERS_PER_PAGE, HOME_PAGE);
  };

  const handleViewCustomerDetail = (customer: CustomerType) => {
    dispatch(selectCustomer(customer));
    navigate('/customers/customers-details');
  };

  useEffect(() => {
    setLoading(true);
    registerLocale('pt-BR', pt);
    getCustomersData(CUSTOMERS_PER_PAGE, HOME_PAGE);
  }, [getCustomersData]);

  const changeAdvancedFilter = () => {
    setAdvancedFilter(!advancedFilter);
    if (advancedFilter) getCustomersData(CUSTOMERS_PER_PAGE, HOME_PAGE);
  };

  useEffect(() => {
    if (filtered) {
      setAdvancedFilter(true);
      setFilterStatus(false);

      const data: FilterType = {
        editionsStatus: 'pending',
      };

      getCustomersData(CUSTOMERS_PER_PAGE, HOME_PAGE, data);
    }
  }, [filtered, getCustomersData]);

  useEffect(() => {
    setDescriptionPagination(
      `${
        (selectedPage - 1) * CUSTOMERS_PER_PAGE +
        customers.length -
        (customers.length - 1)
      }-${
        (selectedPage - 1) * CUSTOMERS_PER_PAGE + customers.length
      } de ${totalCustomers} Pessoas`,
    );
  }, [customers.length, selectedPage, totalCustomers]);

  return (
    <Container>
      <TitlePage title="Lista de pessoas" />
      <Context>
        {loading && <SkeletonList />}
        {!loading && (
          <>
            <Header>
              <InputSearchStyled
                icon={SearchIcon}
                name="search"
                type="text"
                placeholder="Pesquisar por nome"
                value={search}
                onChange={e => setSearch(e.target.value)}
                onIconClick={() =>
                  customerAdvancedFilter(CUSTOMERS_PER_PAGE, HOME_PAGE)
                }
              />
              <ButtonFilter variant="secondary" onClick={changeAdvancedFilter}>
                Avançado
                {advancedFilter ? (
                  <ArrowTopIconStyled />
                ) : (
                  <ArrowDownIconStyled />
                )}
              </ButtonFilter>
            </Header>
            {advancedFilter && (
              <ContextFilter>
                <HeaderFilter>
                  <FormGroup sm={4} md={3} xl={3}>
                    <Label>Último login</Label>
                    <DatePickerStyled
                      selectsRange
                      isClearable
                      startDate={startLastLoginDate}
                      endDate={endLastLoginDate}
                      placeholderText="Período entre datas"
                      onChange={(date: [Date | null, Date | null]) =>
                        date && setLastLogin(date)
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="pt-BR"
                      dateFormat="P"
                      withPortal
                    />
                  </FormGroup>
                  <FormGroup sm={4} md={3} xl={3}>
                    <Label>Nascimento</Label>
                    <DatePickerStyled
                      selectsRange
                      isClearable
                      startDate={startBirth}
                      endDate={endBirth}
                      placeholderText="Período entre datas"
                      onChange={(date: [Date | null, Date | null]) =>
                        date && setBirth(date)
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="pt-BR"
                      dateFormat="P"
                      withPortal
                    />
                  </FormGroup>
                  <FormGroup sm={4} md={3} xl={3}>
                    <Label>Data de criação</Label>
                    <DatePickerStyled
                      selectsRange
                      isClearable
                      startDate={startCreationDate}
                      endDate={endCreationDate}
                      placeholderText="Período entre datas"
                      onChange={(date: [Date | null, Date | null]) =>
                        date && setCreationDate(date)
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="pt-BR"
                      dateFormat="P"
                      withPortal
                    />
                  </FormGroup>
                  <FormGroup sm={4} md={3} xl={3}>
                    <Label>Status</Label>
                    <GroupButton>
                      <ButtonStyled
                        onClick={() => setFilterStatus(true)}
                        variant={
                          filterStatus && filterStatus !== 'init'
                            ? 'primary'
                            : 'secondary'
                        }
                      >
                        Ativo
                      </ButtonStyled>
                      <ButtonStyled
                        onClick={() => setFilterStatus(false)}
                        variant={
                          !filterStatus && filterStatus !== 'init'
                            ? 'primary'
                            : 'secondary'
                        }
                      >
                        Pendente
                      </ButtonStyled>
                    </GroupButton>
                  </FormGroup>
                  <FormGroup sm={4} md={3} xl={3}>
                    <Label>Origem</Label>
                    <GroupButton>
                      <ButtonStyled
                        onClick={() => setFilterOrigin(true)}
                        variant={
                          filterOrigin && filterOrigin !== 'init'
                            ? 'primary'
                            : 'secondary'
                        }
                      >
                        Aplicativo
                      </ButtonStyled>
                      <ButtonStyled
                        onClick={() => setFilterOrigin(false)}
                        variant={
                          !filterOrigin && filterOrigin !== 'init'
                            ? 'primary'
                            : 'secondary'
                        }
                      >
                        Portal
                      </ButtonStyled>
                    </GroupButton>
                  </FormGroup>
                  <FormGroup sm={4} md={3} xl={3}>
                    <Label>Tipo</Label>
                    <GroupButton>
                      <ButtonStyled
                        onClick={() => setFilterType(true)}
                        variant={
                          filterType && filterType !== 'init'
                            ? 'primary'
                            : 'secondary'
                        }
                      >
                        Beneficiário
                      </ButtonStyled>
                      <ButtonStyled
                        onClick={() => setFilterType(false)}
                        variant={
                          !filterType && filterType !== 'init'
                            ? 'primary'
                            : 'secondary'
                        }
                      >
                        Titular
                      </ButtonStyled>
                    </GroupButton>
                  </FormGroup>
                </HeaderFilter>
                <FooterFilter>
                  <ButtonStyled
                    onClick={cleanFilter}
                    variant="secondary"
                    disabled={loadingClean}
                  >
                    {loadingClean ? (
                      <LoginSpinner animation="border" variant="light" />
                    ) : (
                      'Limpar'
                    )}
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={() =>
                      customerAdvancedFilter(CUSTOMERS_PER_PAGE, HOME_PAGE)
                    }
                    disabled={loading}
                  >
                    {loading ? (
                      <LoginSpinner animation="border" variant="light" />
                    ) : (
                      'Aplicar'
                    )}
                  </ButtonStyled>
                </FooterFilter>
              </ContextFilter>
            )}
            <TableStyled hover>
              <THead>
                <tr>
                  <Th>Nome</Th>
                  <Th>CPF</Th>
                  <Th>Origem</Th>
                  <Th>Tipo</Th>
                  <Th>Status</Th>
                </tr>
              </THead>
              <tbody>
                {customers.map((customer: CustomerType) => (
                  <Tr
                    key={customer.id_customer}
                    onClick={() => handleViewCustomerDetail(customer)}
                  >
                    <Td>{customer.name}</Td>
                    <Td>
                      <Flag text={hideCpf(customer.username)} />
                    </Td>
                    <Td>{customer.origin}</Td>
                    <Td>{getTranslation(customer.type)}</Td>
                    <Td>
                      <Flag
                        text={
                          customer.editions && customer.editions === 'pending'
                            ? 'Pendente'
                            : 'Ativo'
                        }
                        status={
                          customer.editions && customer.editions === 'pending'
                            ? 'warning'
                            : 'success'
                        }
                      />
                    </Td>
                  </Tr>
                ))}
              </tbody>
              {customers.length > 0 && (
                <tfoot>
                  <tr>
                    <td colSpan={5}>
                      <AreaPagination>
                        <DescriptionPagination style={{ visibility: 'hidden' }}>
                          {descriptionPagination}
                        </DescriptionPagination>
                        <PaginationStyled size="sm">
                          <Pagination.First
                            onClick={() => changePage(HOME_PAGE)}
                          />
                          <Pagination.Prev
                            onClick={() => changePage(selectedPage - 1)}
                          />
                          {paginationItem
                            .slice(pageInit, pageLimit)
                            .map((page: number) => (
                              <Pagination.Item
                                key={page}
                                active={page === selectedPage}
                                onClick={() => changePage(page)}
                              >
                                {page}
                              </Pagination.Item>
                            ))}
                          <Pagination.Next
                            onClick={() => changePage(selectedPage + 1)}
                          />
                          <Pagination.Last
                            onClick={() => changePage(paginationItem.length)}
                          />
                        </PaginationStyled>
                        <DescriptionPagination>
                          {descriptionPagination}
                        </DescriptionPagination>
                      </AreaPagination>
                    </td>
                  </tr>
                </tfoot>
              )}
            </TableStyled>
            {customers.length <= 0 && (
              <AlertTable>Nenhum cliente encontrado</AlertTable>
            )}
          </>
        )}
      </Context>
    </Container>
  );
};

export default Customers;
