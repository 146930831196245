import React from 'react';
import { Container, Title } from './styles';

interface TitlePageProps {
  title: string;
}

const TitlePage = ({ title }: TitlePageProps) => {
  return (
    <Container>
      <Title>{title}</Title>
    </Container>
  );
};

export default TitlePage;
