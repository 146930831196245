/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { toast } from 'react-toastify';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {
  updateContract,
  createContract,
  deleteContract,
  getContract,
} from '../../../services/Contract.service';
import { useAppSelector } from '../../../store';
import { theme } from '../../../global/styles/theme';
import TermsContractsType from '../../../types/termsContracts.type';
import CompanyType from '../../../types/company.type';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FloatFooter from '../../../Components/FloatFooter';
import QuestionTooltip from '../../../Components/QuestionTooltip';

import {
  Container,
  Dot,
  GoBackIcon,
  NavGoBack,
  NavGoBackContainer,
  NavGoBackName,
  Context,
  InputStyled,
  RowForm,
  Label,
  ButtonContainer,
  ButtonStyled,
  Header,
  LabelId,
  LoginSpinner,
  Select,
  LabelTooltip,
} from './styles';

const ContractsDetails = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [titleButton, setTitleButton] = useState('Adicionar');

  const termsContract = useAppSelector(
    state => state.TermsContractReducer?.termsContract,
  );
  const companies = useAppSelector(state => state.companyReducer.companies);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleGoBack = () => {
    navigate('/customers/contracts');
  };

  const convertToHTML = (editorState: any) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const convertFromHTMLToEditorState = (html: string) => {
    const blocksFromHTML = convertFromHTML(html);

    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );

    return EditorState.createWithContent(state);
  };

  const onEditorStateChange = (editorState: any) => {
    setEditor(editorState);
    return convertToHTML(editorState);
  };

  const getTermsContract = useCallback(async () => {
    const { data, status, msg } = await getContract(termsContract._id);

    if (!status) toast.error(msg);

    if (data.term.content) {
      setEditor(convertFromHTMLToEditorState(data.term.content));
    }
  }, [termsContract]);

  useEffect(() => {
    setTitle(termsContract.term.title);
    if (termsContract.id_company) setCompanyId(termsContract.id_company);
    if (termsContract._id) {
      getTermsContract();
      setTitleButton('Atualizar');
    }
    if (termsContract.term.content) {
      setEditor(convertFromHTMLToEditorState(termsContract.term.content));
    }
  }, [termsContract, getTermsContract]);

  const findCompany = (companyIdFind: string) => {
    const companyTmp = companies.filter(
      (companyFind: CompanyType) => companyFind._id === companyIdFind,
    );

    return companyTmp.length > 0 ? companyTmp[0].id_group : '';
  };

  const handleUpdateTermsContract = async () => {
    if (!title || !editor || !companyId) {
      setLoading(false);
      return toast.error('É necessário preencher todos os campos');
    }

    const values: TermsContractsType = {
      contract: termsContract.contract,
      version: termsContract.version,
      id_company: companyId,
      id_group: findCompany(companyId),
      term: {
        title,
        content: convertToHTML(editor),
      },
    };

    const { status, msg } = await updateContract(termsContract._id, values);

    setLoading(false);
    if (!status) return toast.error(msg);

    toast.success('Contrato atualizado com sucesso!');
    return handleGoBack();
  };

  const handleAddTermsContract = async () => {
    if (!title || !editor || !companyId) {
      setLoading(false);
      return toast.error('É necessário preencher todos os campos');
    }

    const values: TermsContractsType = {
      contract: '1',
      version: '1',
      id_company: companyId,
      id_group: findCompany(companyId),
      term: {
        title,
        content: convertToHTML(editor),
      },
    };

    const { status, msg } = await createContract(values);

    setLoading(false);
    if (!status) return toast.error(msg);

    toast.success('Contrato adicionado com sucesso!');
    return handleGoBack();
  };

  const handleDeleteTermsContract = async () => {
    const { status, msg } = await deleteContract(termsContract._id);

    if (!status) return toast.error(msg);

    toast.success('Contrato deletado com sucesso!');
    return handleGoBack();
  };

  const handleSubmit = () => {
    setLoading(true);
    if (termsContract._id) return handleUpdateTermsContract();
    return handleAddTermsContract();
  };

  const SelectVariables = useCallback((props: any) => {
    return (
      <Label style={{ display: 'flex', alignItems: 'baseline' }}>
        <Label title="Cadastro de tipo" />
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tooltip2">
              <p>
                O Cadastro de tipo é separado inicialmente por 2 modelos de
                notificações:
              </p>
              <strong>Cadastral:</strong> aniversário, mensal, data fixa e
              ativação de contrato.
              <br />
              <strong>Financeiro:</strong> Parcelas em atraso, vencidas e com
              vencimento próximo.
              <br />
            </Tooltip>
          }
        >
          <div>
            <LabelTooltip>
              Variável <QuestionTooltip />
            </LabelTooltip>
          </div>
        </OverlayTrigger>
      </Label>
    );
  }, []);

  return (
    <>
      <FloatFooter>
        <ButtonContainer>
          {termsContract._id && (
            <ButtonStyled variant="secondary" onClick={handleShow}>
              Deletar
            </ButtonStyled>
          )}
          <ButtonStyled
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <LoginSpinner animation="border" variant="light" />
            ) : (
              titleButton
            )}
          </ButtonStyled>
        </ButtonContainer>
      </FloatFooter>
      <Container>
        <Header>
          <NavGoBackContainer onClick={handleGoBack}>
            <GoBackIcon />
            <NavGoBack>Lista</NavGoBack>
            {title && <Dot />}
            <NavGoBackName>{title || ''}</NavGoBackName>
          </NavGoBackContainer>
          {termsContract._id && <LabelId>{`ID: ${termsContract._id}`}</LabelId>}
        </Header>
        <Context>
          <RowForm>
            <Label>Título</Label>
            <InputStyled
              name="name"
              type="text"
              value={title}
              onChange={(e: any) => setTitle(e.target.value)}
            />
          </RowForm>
          <RowForm>
            <Label>Empresas</Label>
            <Select
              onChange={(e: any) => setCompanyId(e.target.value)}
              value={companyId}
            >
              {companies?.map((companySelect: CompanyType) => (
                <option value={companySelect._id}>{companySelect.name}</option>
              ))}
            </Select>
          </RowForm>
          <Editor
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              link: { inDropdown: true },
            }}
            editorStyle={{
              backgroundColor: theme.colors.white,
              padding: '1.5rem 2.5rem',
              boxShadow: '0px 10px 16px -3px rgba(0,0,0,0.38)',
              borderCollapse: 'separate',
              borderSpacing: 0,
              borderRadius: 0,
              minHeight: '800px',
              margin: '0 auto',
              fontSize: '15px',
              cursor: 'text',
            }}
            toolbarStyle={{
              Color: '#000',
            }}
            editorState={editor}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            onEditorStateChange={onEditorStateChange}
            toolbarCustomButtons={[<SelectVariables />]}
            mention={{
              separator: ' ',
              trigger: '{',
              suggestions: [
                {
                  text: 'Nome do cliente',
                  value: '{nome_cliente}}',
                  url: '{{NomeDoCliente}}',
                },
                {
                  text: 'Endereço',
                  value: '{endereco}}',
                  url: 'NomeDoCliente',
                },
                {
                  text: 'Data de Nascimento',
                  value: '{data_nascimento}}',
                  url: 'DtNascimento',
                },
                {
                  text: 'CPF',
                  value: '{cpf}}',
                  url: 'Cpf',
                },
                {
                  text: 'Telefone',
                  value: '{telefone}}',
                  url: 'Telefone',
                },
                {
                  text: 'Beneficiários',
                  value: '{beneficiarios}}',
                  url: 'Beneficiarios',
                },
                {
                  text: 'Tipo',
                  value: '{tipo}}',
                  url: 'Cliente',
                },
                {
                  text: 'Planos',
                  value: '{planos}}',
                  url: 'Planos',
                },
                {
                  text: 'Origem',
                  value: '{Origem}}',
                  url: 'Origem',
                },
              ],
            }}
            hashtag={{}}
          />
        </Context>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Atenção!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Realmente deseja deletar o contrato {title}?</Modal.Body>
          <Modal.Footer>
            <ButtonStyled variant="secondary" onClick={handleClose}>
              Cancelar
            </ButtonStyled>
            <ButtonStyled variant="danger" onClick={handleDeleteTermsContract}>
              Deletar
            </ButtonStyled>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default ContractsDetails;
