import React, { useState } from 'react';
import SkeletonHome from '../../Components/SkeletonHome';
import { Container, Context, IFrame, WrapperIFrame } from './styles';

const Customers: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <Container>
      {loading && <SkeletonHome />}
      <Context>
        <WrapperIFrame>
          <IFrame
            id="iframe-marketing"
            title="Home marketing"
            src="https://plataformavirtus.com.br/home-marketing/"
            onLoad={onLoad}
          />
        </WrapperIFrame>
      </Context>
    </Container>
  );
};

export default Customers;
