import React from 'react';
import ReactIcon from 'react-icons';
import { FormControlProps } from 'react-bootstrap';

import { Container, Input, ContainerIcon } from './styles';

interface InputProps extends FormControlProps {
  name: string;
  icon?: React.ComponentType<ReactIcon.IconBaseProps>;
  onIconClick?: () => void;
}

const defaultProps = {
  icon: undefined,
  onIconClick: undefined,
};

const InputSearch: React.FC<InputProps> = ({
  icon: Icon,
  onIconClick,
  ...rest
}) => {
  const handleIconClick = () => {
    if (onIconClick) {
      onIconClick();
    }
  };

  return (
    <Container>
      <Input {...rest} />
      <ContainerIcon onClick={handleIconClick}>
        {Icon && <Icon size={30} />}
      </ContainerIcon>
    </Container>
  );
};

InputSearch.defaultProps = defaultProps;

export default InputSearch;
