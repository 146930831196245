import styled, { css } from 'styled-components';
import { theme } from '../../global/styles/theme';

import { ReactComponent as InputPeople } from '../../assets/svgs/people.svg';
import { ReactComponent as InputHandShake } from '../../assets/svgs/handshake.svg';

interface MenuProps {
  selected: boolean;
}

interface ContextProps {
  isShowSubMenu: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0px;
  position: fixed;
  z-index: 9;
  background-color: #00000075;
  margin-top: 49px;
  animation: showContainer 0.4s;
  @keyframes showContainer {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
      left: 0px;
    }
  }
`;

export const Context = styled.div<ContextProps>`
  height: 100%;
  width: 16rem;
  background: ${theme.colors.white};

  margin-left: 6.2rem;
  margin-top: 3rem;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  animation: showSidebar 0.4s;

  @keyframes showSidebar {
    from {
      opacity: 0;
      left: -16rem;
      animation: 0.4s;
    }
    to {
      opacity: 1;
      left: 0px;
    }
  }
  ${(props: ContextProps) => {
    if (!props.isShowSubMenu) {
      return css`
        animation: 0.4s;
      `;
    }
    return '';
  }}
`;

export const IconPeople = styled(InputPeople)`
  width: 1.3rem;
  height: 100%;
  margin-right: 0.5rem;
`;
export const IconHandShake = styled(InputHandShake)`
  width: 1.3rem;
  height: 100%;
  margin-right: 0.5rem;
`;

export const Title = styled.h3`
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 75%;
  color: ${theme.colors.text.tHead};

  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const Menu = styled.h5`
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 80%;
  color: inherit;
  margin-left: 1.8rem;
  margin-bottom: 0;
  :hover {
    font-weight: 600;
  }
  :active {
    font-weight: 600;
  }
  :focus {
    font-weight: 600;
  }
`;

export const ContainerMenu = styled.div<MenuProps>`
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
  height: 2.5rem;
  width: 100%;
  cursor: pointer;
  transition: 0.5s;
  color: ${theme.colors.text.tHead};

  &:hover {
    border-radius: 8px;
    background: ${theme.colors.backgroundPrimary};
    color: ${theme.colors.primary};
  }

  ${(props: MenuProps) => {
    if (props.selected) {
      return css`
        border-radius: 8px;
        background: ${theme.colors.backgroundPrimary};
        color: ${theme.colors.primary};
      `;
    }
    return '';
  }}
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ButtonClose = styled.button`
  border: none;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 75%;
  color: ${theme.colors.text.tHead};
  background: ${theme.colors.white};
`;
