import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  padding: 0.5rem 0rem 0rem 0.3rem;
`;

export const MediaUpload = styled.div`
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  color: #333333;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid #bebebe;
  box-sizing: border-box;
  border-radius: 8px;
  input {
    height: 64px;
    cursor: pointer;
  }
  img {
    overflow: hidden;
    width: 3.5rem;
    height: 3.5rem;
    object-fit: cover;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    margin: 6px;
    border-color: rgb(200, 200, 200);
    padding: 5px;
    display: none;
  }
`;
