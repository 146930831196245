import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useState,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { Container, MediaUpload } from './styles';

interface HTMLInputEvent
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  target: HTMLInputElement & EventTarget;
}

interface FileUploadProps {
  onChangeFile: (img: FormData) => void;
  urlImage: string;
}

const FileUpload = ({ onChangeFile, urlImage }: FileUploadProps) => {
  const [file, setFile] = useState('');
  const [isActive, setIsActive] = useState(false);

  const sendImage = (img: File) => {
    const formData = new FormData();

    formData.append('file', img);

    onChangeFile(formData);
  };

  const handleChange = (e: HTMLInputEvent) => {
    if (e.target.files?.length) {
      const fileObject: any = e.target.files[0];

      if (
        fileObject.type === 'image/png' ||
        fileObject.type === 'image/jpeg' ||
        fileObject.type === 'image/jpg'
      ) {
        setFile(URL.createObjectURL(fileObject));
        sendImage(fileObject);
        setIsActive(true);
      } else {
        toast.info('Selecione uma imagem do tipo .png, .jpeg e .jpg');
        setIsActive(false);
      }
    } else setIsActive(false);
  };

  useEffect(() => {
    if (urlImage) {
      setFile(urlImage);
      setIsActive(true);
    }
  }, [urlImage]);

  return (
    <Container>
      <Form.Group controlId="formFile" className="mb-3">
        <MediaUpload
          style={{
            backgroundColor: isActive ? 'rgb(246 237 255)' : '',
            borderColor: isActive ? 'rgb(141 90 188)' : '',
          }}
        >
          <img
            alt=""
            src={file}
            style={{
              display: isActive ? 'initial' : '',
            }}
          />
          <input
            type="file"
            onChange={handleChange}
            accept="image/png, image/jpeg, image/jpg"
          />
        </MediaUpload>
      </Form.Group>
    </Container>
  );
};

export default FileUpload;
