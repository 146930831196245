/* eslint-disable default-case */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { Pagination } from 'react-bootstrap';
import { toast } from 'react-toastify';
import TitlePage from '../../../Components/TitlePage';
import UserTotal from '../../../assets/svgs/usertotal.svg';
import EmptyList from '../../../assets/svgs/emptylistclean.svg';
import { useAppSelector } from '../../../store';
import '../../../custom.scss';

import { getDashboardPartners } from '../../../services/Partners.service';

import {
  Container,
  ColumnTwelve,
  Label,
  ColumnSix,
  TitleLabel,
  TotalNumber,
  UserTotalIcon,
  CardBackgroundEffect,
  PaginationStyled,
  TableStyled,
  THead,
  Th,
  Td,
  Tr,
  CardSurvey,
  EmptyListIcon,
} from './styles';

interface TotalPartnersByCategoryType {
  category: string;
  totalPartners: number;
}

const CustomersDashboard = () => {
  const CATEGORIES_PER_PAGE = 5;

  const [totalPartners, setTotalPartners] = useState(0);
  const [totalPartnersByCategoryTmp, setTotalPartnersByCategoryTmp] = useState<
    TotalPartnersByCategoryType[]
  >([]);
  const [totalPartnersByCategory, setTotalPartnersByCategory] = useState<
    TotalPartnersByCategoryType[]
  >([]);
  const [paginationItem, setPaginationItem] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [categoriesPerPage, setCategoriesPerPage] =
    useState<number>(CATEGORIES_PER_PAGE);

  const companies = useAppSelector(
    state => state.companyReducer.companySelected,
  );

  const fetchData = useCallback(async () => {
    const { data, msg, status } = await getDashboardPartners(
      companies.id_group,
    );

    if (!status) toast.error(msg);
    else {
      setTotalPartners(data.totalPartners);
      setTotalPartnersByCategoryTmp(
        data.totalPartnersByCategory.slice(selectedPage, categoriesPerPage),
      );
      setTotalPartnersByCategory(data.totalPartnersByCategory);

      const countItem = [];
      for (
        let num = 1;
        num <=
        Math.ceil(data.totalPartnersByCategory.length / CATEGORIES_PER_PAGE);
        num += 1
      ) {
        countItem.push(num);
      }

      setPaginationItem(countItem);
    }
  }, [categoriesPerPage, companies.id_group, selectedPage]);

  const changePage = (page: number) => {
    if (page > 0 && page <= paginationItem.length) {
      setSelectedPage(page);
      setCategoriesPerPage(page + 5);
      setTotalPartnersByCategoryTmp(
        totalPartnersByCategory.slice(page, page + 5),
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container>
      <TitlePage title="Visão Geral" />

      <ColumnTwelve
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <ColumnSix
          style={{
            flex: '0 0 39%',
          }}
        >
          <CardBackgroundEffect>
            <Label
              style={{
                display: 'flex',
                marginTop: '10px',
                alignItems: 'baseline',
              }}
            >
              <TitleLabel
                style={{
                  fontSize: '1.4rem',
                  margin: 'auto',
                  color: '#fff',
                }}
              >
                Total de Parceiros
              </TitleLabel>
            </Label>
            <UserTotalIcon style={{ backgroundImage: `url(${UserTotal})` }} />
            <TotalNumber style={{ marginBottom: '1.5rem' }}>
              {totalPartners}
            </TotalNumber>
          </CardBackgroundEffect>
        </ColumnSix>
        <ColumnSix style={{ flex: '0 0 59%', flexDirection: 'column' }}>
          <ColumnTwelve>
            {totalPartnersByCategory.length === 0 && (
              <CardSurvey>
                <EmptyListIcon
                  style={{ backgroundImage: `url(${EmptyList})` }}
                />
              </CardSurvey>
            )}
            {totalPartnersByCategory.length > 0 && (
              <TableStyled
                style={{
                  margin: '2rem auto',
                }}
                hover
              >
                <THead>
                  <tr>
                    <Th>Categoria</Th>
                    <Th>Quantidade</Th>
                  </tr>
                </THead>
                <tbody>
                  {totalPartnersByCategoryTmp.map(
                    (partnersBycategory: TotalPartnersByCategoryType) => (
                      <Tr>
                        <Td>{partnersBycategory.category}</Td>
                        <Td>{partnersBycategory.totalPartners}</Td>
                      </Tr>
                    ),
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      style={{
                        padding: '10px !important',
                      }}
                      colSpan={5}
                    >
                      <PaginationStyled size="sm">
                        <Pagination.Prev
                          onClick={() => changePage(selectedPage - 1)}
                        />
                        {paginationItem.map((page: number) => (
                          <Pagination.Item
                            key={page}
                            active={page === selectedPage}
                            onClick={() => changePage(page)}
                          >
                            {page}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={() => changePage(selectedPage + 1)}
                        />
                      </PaginationStyled>
                    </td>
                  </tr>
                </tfoot>
              </TableStyled>
            )}
          </ColumnTwelve>
        </ColumnSix>
      </ColumnTwelve>
    </Container>
  );
};

export default CustomersDashboard;
