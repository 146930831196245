import styled from 'styled-components';
import { Table, Pagination, Button, Row, Col, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import InputSearch from '../../Components/InputSearch';

import { theme } from '../../global/styles/theme';

import { ReactComponent as ArrowDownIcon } from '../../assets/svgs/arrow-down.svg';

const { colors } = theme;

export const Container = styled.div`
  width: 100%;
  margin: 4.5rem auto;
  padding-left: 8rem;
  padding-right: 1.6rem;
`;

export const Context = styled.div`
  width: 100%;
  margin: 2rem auto;
`;

export const InputSearchStyled = styled(InputSearch)`
  min-width: 28rem;
  background-color: #ffffff !important;

  @media (max-width: 780px) {
    min-width: 20rem;
  }

  @media (max-width: 648px) {
    min-width: 14rem;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;

  @media (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const ButtonFilter = styled(Button)`
  margin-left: 0.6rem;
  border-radius: 10px;
  color: ${colors.text.title};
  background-color: #cccccc;
  border-color: #cccccc;

  @media (max-width: 550px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }

  :hover {
    path {
      fill: ${colors.white};
    }
  }

  :focus {
    path {
      fill: ${colors.white};
    }
  }
`;

export const TableStyled = styled(Table)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: ${colors.white};

  border-collapse: separate;
  border-spacing: 0;
`;

export const THead = styled.thead`
  color: ${colors.text.tHead};
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  border-bottom-color: ${colors.background};
`;

export const Th = styled.th`
  padding: 1rem 0.5rem !important;
  padding-left: 1rem !important;
`;

export const Td = styled.td`
  padding-left: 1rem !important;
  color: ${colors.text.title};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
`;

export const Tr = styled.tr`
  cursor: pointer;
`;

export const PaginationStyled = styled(Pagination)`
  margin: 0.6rem 0;
  margin-right: 0.6rem;
  justify-content: flex-end;
`;

export const ContextFilter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.1rem;
  margin-bottom: 2rem;
  background: ${colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  /* visibility: hidden;
  opacity: 0; */
  /* transition: visibility 1s, opacity 0.5s; */
  :start {
    transition-delay: 1s;
  }
`;

export const HeaderFilter = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 900px) {
    justify-content: space-around;
    margin-top: 0.6rem;
  }

  @media (max-width: 935px) {
    margin-top: 0.8rem;
  }
`;

export const FooterFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 0.6rem;
  color: ${colors.text.title};
`;

export const FormGroup = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0.8rem;

  @media (max-width: 1040px) {
    margin: 1rem;
    margin-top: 0.8rem;
  }

  @media (max-width: 1000px) {
    margin: 1.2rem;
    margin-top: 0.6rem;
  }

  @media (max-width: 975px) {
    margin: 1.5rem;
    margin-top: 0.5rem;
  }

  @media (max-width: 935px) {
    margin: 1.8rem;
    margin-top: 0.3rem;
  }

  @media (max-width: 900px) {
    margin: 2rem;
    margin-top: 0.2rem;
  }

  .react-datepicker__close-icon {
    position: inherit;
    padding: 0;
    margin-left: 0.4rem;
  }

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
  }

  .react-datepicker__close-icon::after {
    background-color: ${theme.colors.primary};
    vertical-align: initial;
    height: 18px;
    width: 18px;
  }
`;

export const GroupButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ButtonStyled = styled(Button)`
  margin-right: 0.4rem;
  border-radius: 10px;
`;

export const ArrowDownIconStyled = styled(ArrowDownIcon)`
  margin-left: 0.5rem;
`;

export const ArrowTopIconStyled = styled(ArrowDownIcon)`
  margin-left: 0.5rem;
  transform: rotate(180deg);
`;

export const DatePickerStyled = styled(DatePicker)`
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
  max-width: 14rem;
  width: 100%;
  min-height: 2.4rem;
  background-color: ${colors.white};
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: ${colors.text.title};
  font-weight: 400;
  font-size: 13px;
  border: 1px solid ${colors.borderInput};
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 0.6rem;

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::placeholder,
  &:-ms-input-placeholder {
    color: ${colors.background};
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border: 1px solid ${colors.primary};
    box-shadow: none;
  }
`;

export const LoginSpinner = styled(Spinner)`
  height: 1.4rem;
  width: 1.4rem;
`;

export const AlertTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 0.8rem;
  background: ${colors.flag.error.background};
  color: ${colors.flag.error.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const AreaPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DescriptionPagination = styled.span`
  font-size: 12px;
`;
