/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CompanyType from '../../../types/company.type';

export interface CompanySelected {
  _id: string;
  cnpj: string;
  name: string;
  id_group: string;
}

export interface CompanyState {
  companies: CompanyType[] | null;
  companySelected: CompanySelected;
}

const initialState: CompanyState = {
  companies: null,
  companySelected: {
    _id: '',
    cnpj: '',
    name: '',
    id_group: '',
  },
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    companySuccess: (state, action: PayloadAction<CompanyType[]>) => {
      state.companies = action.payload;
    },
    switchCompany: (state, action: PayloadAction<CompanySelected>) => {
      state.companySelected = action.payload;
    },
    removeCompany: state => {
      state.companies = initialState.companies;
      state.companySelected = initialState.companySelected;
    },
  },
});

export const { companySuccess, switchCompany, removeCompany } =
  companySlice.actions;

export default companySlice.reducer;
