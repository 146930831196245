/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TermsContractsType from '../../../types/termsContracts.type';

export interface TermsContractState {
  termsContract: TermsContractsType;
}

const initialState: TermsContractState = {
  termsContract: {
    term: {
      content: '',
      title: '',
    },
    _id: '',
    id_company: '',
    id_group: '',
    version: '',
    contract: '',
    updatedAt: '',
  },
};

export const termsContractSlice = createSlice({
  name: 'termsContract',
  initialState,
  reducers: {
    selectTermsContract: (state, action: PayloadAction<TermsContractsType>) => {
      state.termsContract = action.payload;
    },
  },
});

export const { selectTermsContract } = termsContractSlice.actions;

export default termsContractSlice.reducer;
