import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonList = () => {
  return (
    <div className="skeleton-container">
      <div
        className="skeleton-title"
        style={{ marginBottom: '32px', display: 'flex' }}
      >
        <Skeleton height={45} width={500} />
        <Skeleton
          height={45}
          width={70}
          style={{ marginLeft: '10px', width: '130' }}
        />
      </div>

      <ul
        style={{
          backgroundColor: '#ffffff',
          padding: '20px',
          boxShadow: '0px 4px 4px rgb(0 0 0 / 8%)',
          borderRadius: '5px',
        }}
      >
        {Array(1)
          .fill(0)
          .map(item => (
            <li key={item}>
              <div
                className="item-group"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <div className="skeleton-item" style={{ width: '100%' }}>
                  <Skeleton height={20} width="25%" />
                </div>
                <div className="skeleton-item" style={{ width: '100%' }}>
                  <Skeleton height={20} width="25%" />
                </div>
                <div className="skeleton-item" style={{ width: '100%' }}>
                  <Skeleton height={20} width="25%" />
                </div>
                <div className="skeleton-item" style={{ width: '100%' }}>
                  <Skeleton height={20} width="25%" />
                </div>
              </div>
            </li>
          ))}
        {Array(6)
          .fill(0)
          .map(item => (
            <li key={item}>
              <div
                className="item-group"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div
                  className="skeleton-item"
                  style={{ width: '100%', padding: '10px 0px' }}
                >
                  <Skeleton height={30} width="95%" />
                </div>
                <div
                  className="skeleton-item"
                  style={{ width: '100%', padding: '10px 0px' }}
                >
                  <Skeleton height={30} width="95%" />
                </div>
                <div
                  className="skeleton-item"
                  style={{ width: '100%', padding: '10px 0px' }}
                >
                  <Skeleton height={30} width="95%" />
                </div>
                <div
                  className="skeleton-item"
                  style={{ width: '100%', padding: '10px 0px' }}
                >
                  <Skeleton height={30} width="95%" />
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SkeletonList;
