import React from 'react';
import { Container } from './styles';

interface TagProps {
  text: string;
  status?: string;
}

const defaultProps = {
  status: '',
};

const Tag: React.FC<TagProps> = ({ text, status }) => {
  return <Container status={String(status)}>{text}</Container>;
};

Tag.defaultProps = defaultProps;

export default Tag;
