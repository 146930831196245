/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { format, getDate, setDate } from 'date-fns';
import { Pagination } from 'react-bootstrap';

import TitlePage from '../../Components/TitlePage';
import { getContracts } from '../../services/Contract.service';
import { useAppSelector, useAppDispatch } from '../../store';
import TermsContractsType from '../../types/termsContracts.type';
import { selectTermsContract } from '../../store/slices/TermsContract/TermsContract.slice';
import SkeletonListEndBar from '../../Components/SkeletonListEndBar';

import {
  Container,
  Context,
  Header,
  AlertTable,
  ButtonFilter,
  THead,
  TableStyled,
  Td,
  Th,
  Tr,
  PaginationStyled,
  AreaPagination,
  DescriptionPagination,
} from './styles';
import isDate from '../../utils/isDate';
import ptBR from '../../utils/locale';
import CompanyType from '../../types/company.type';

const Contracts: React.FC = () => {
  const CONTRACTS_PER_PAGE = 6;
  const HOME_PAGE = 1;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company = useAppSelector(state => state.companyReducer.companySelected);
  const companies = useAppSelector(state => state.companyReducer.companies);

  const [contracts, setContracts] = useState<TermsContractsType[]>([]);
  const [totalContracts, setTotalContracts] = useState<number>(0);
  const [paginationItem, setPaginationItem] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(HOME_PAGE);
  const [loading, setLoading] = useState<boolean>(false);
  const [descriptionPagination, setDescriptionPagination] = useState('');
  const [pageInit, setPageInit] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);

  const termsContractInitial: TermsContractsType = {
    term: {
      content: '',
      title: '',
    },
    _id: '',
    id_company: '',
    id_group: '',
    version: '',
    updatedAt: '',
  };

  const getAllContracts = useCallback(
    async (page: number, limit: number) => {
      const { data, status, msg } = await getContracts(
        company.id_group,
        page,
        limit,
      );

      if (!status) {
        setLoading(false);
        toast.error(msg);
      } else {
        setContracts(data?.contractTerms);
        setTotalContracts(data?.totalDocs);

        const countItem = [];
        for (
          let num = 1;
          num <= Math.ceil(data.totalDocs / CONTRACTS_PER_PAGE);
          num += 1
        ) {
          countItem.push(num);
        }

        if (countItem.length <= 0 && data?.contractTerms.length > 0) {
          setPaginationItem([1]);
        } else setPaginationItem(countItem);
        setLoading(false);
      }
    },
    [company],
  );

  const handleViewContractDetail = (terms: TermsContractsType) => {
    dispatch(selectTermsContract(terms));
    navigate('/customers/contracts/details');
  };

  const formatDate = (date: string) => {
    if (!isDate(date)) return '';

    let newDate = new Date(
      date.indexOf('T') !== -1 ? date.split('T')[0] : date,
    );

    newDate = setDate(newDate, getDate(newDate) + 1);

    return format(newDate, "dd 'de' MMMM", {
      locale: ptBR,
    });
  };

  const changePage = (page: number) => {
    if (page > 0 && page <= paginationItem.length) {
      if (page > 5) {
        setPageInit(page - 5);
        setPageLimit(page);
      } else {
        setPageInit(0);
        setPageLimit(5);
      }

      setSelectedPage(page);
      getAllContracts(page, CONTRACTS_PER_PAGE);
    }
  };

  const findCompany = (companyId: string) => {
    const companyTmp = companies.filter(
      (companyFind: CompanyType) => companyFind._id === companyId,
    );

    return companyTmp.length > 0 ? companyTmp[0].name : '';
  };

  useEffect(() => {
    setLoading(true);
    getAllContracts(HOME_PAGE, CONTRACTS_PER_PAGE);
  }, [getAllContracts]);

  useEffect(() => {
    setDescriptionPagination(
      `${
        (selectedPage - 1) * CONTRACTS_PER_PAGE +
        contracts.length -
        (contracts.length - 1)
      }-${
        (selectedPage - 1) * CONTRACTS_PER_PAGE + contracts.length
      } de ${totalContracts} Contratos`,
    );
  }, [contracts.length, selectedPage, totalContracts]);

  return (
    <Container>
      <Header>
        <TitlePage title="Lista de Contratos" />
        <ButtonFilter
          variant="primary"
          onClick={() => handleViewContractDetail(termsContractInitial)}
        >
          Adicionar contrato
        </ButtonFilter>
      </Header>
      <Context>
        {loading && <SkeletonListEndBar />}
        {!loading && (
          <TableStyled hover>
            <THead>
              <tr>
                <Th>ID</Th>
                <Th>Contrato</Th>
                <Th>Empresa</Th>
                <Th>Versão</Th>
                <Th>Última Atualização</Th>
              </tr>
            </THead>
            <tbody>
              {contracts.map((contract: TermsContractsType) => (
                <Tr
                  key={contract._id}
                  onClick={() => handleViewContractDetail(contract)}
                >
                  <Td>{contract._id}</Td>
                  <Td>{contract.term.title}</Td>
                  <Td>{findCompany(contract.id_company)}</Td>
                  <Td>{contract.version}</Td>
                  <Td>{formatDate(contract.updatedAt || '')}</Td>
                </Tr>
              ))}
            </tbody>
            {contracts.length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan={5}>
                    <AreaPagination>
                      <DescriptionPagination style={{ visibility: 'hidden' }}>
                        {descriptionPagination}
                      </DescriptionPagination>
                      <PaginationStyled size="sm">
                        <Pagination.First
                          onClick={() => changePage(HOME_PAGE)}
                        />
                        <Pagination.Prev
                          onClick={() => changePage(selectedPage - 1)}
                        />
                        {paginationItem
                          .slice(pageInit, pageLimit)
                          .map((page: number) => (
                            <Pagination.Item
                              key={page}
                              active={page === selectedPage}
                              onClick={() => changePage(page)}
                            >
                              {page}
                            </Pagination.Item>
                          ))}
                        <Pagination.Next
                          onClick={() => changePage(selectedPage + 1)}
                        />
                        <Pagination.Last
                          onClick={() => changePage(paginationItem.length)}
                        />
                      </PaginationStyled>
                      <DescriptionPagination>
                        {descriptionPagination}
                      </DescriptionPagination>
                    </AreaPagination>
                  </td>
                </tr>
              </tfoot>
            )}
          </TableStyled>
        )}
        {!loading && contracts.length <= 0 && (
          <AlertTable>Nenhuma notificação encontrada</AlertTable>
        )}
      </Context>
    </Container>
  );
};

export default Contracts;
