import styled from 'styled-components';
import { theme } from '../../../global/styles/theme';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  background: ${theme.colors.background};
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Context = styled.div``;
