import { Reducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default (reducers: Reducer<any, any>): Reducer<any, any> => {
  const persistedReducer = persistReducer(
    {
      key: 'management-panel-root',
      version: 1,
      storage,
      whitelist: [
        'signInReducer',
        'companyReducer',
        'customerReducer',
        'TermsContractReducer',
      ],
    },
    reducers,
  );

  return persistedReducer;
};
