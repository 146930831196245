/* eslint-disable default-case */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import ReactApexChart from 'react-apexcharts';
import { toast } from 'react-toastify';
import { format, getDate, setDate } from 'date-fns';
import TitleLabel from '../../../Components/TitleLabel';
import { getSurvey } from '../../../services/Survey.service';
import SettingsSurveyType from '../../../types/settingsSurvey.type';
import '../../../custom.scss';

import {
  Container,
  Dot,
  GoBackIcon,
  NavGoBack,
  NavGoBackContainer,
  NavGoBackName,
  // Context,
  ColumnTwelve,
  // RowForm,
  Label,
  ColumnSix,
  // Header,
  HeaderSurvy,
  StarSatisfaction,
  LabelId,
  THead,
  TableStyled,
  Td,
  Th,
  Tr,
  CardSurvey,
  PaginationStyled,
  ContextFilter,
  HeaderFilter,
  FormGroup,
  DatePickerStyled,
  ButtonStyled,
  FooterFilter,
  LoginSpinner,
  GroupButton,
  Title,
  FormGroupSurvey,
} from './styles';
import isDate from '../../../utils/isDate';
import ptBR from '../../../utils/locale';
import SurveyType from '../../../types/Survey';

interface SurveyAccumulatorsType {
  scoreIndex: number;
  count: number;
}

const ResultsSatisfactionSurveys = () => {
  const NOTIFICATIONS_PER_PAGE = 10;
  const HOME_PAGE = 1;
  const { state }: { state: any } = useLocation();
  const { notificationId, groupId } = state || {
    notificationId: '',
    groupId: '',
  };
  const navigate = useNavigate();

  const [title] = useState('');

  const [surveys, setSurveys] = useState<SurveyType>();
  console.log(surveys);
  const [seriesBar, setSeriesBar] = useState<number[]>([]);
  const [paginationItem, setPaginationItem] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(HOME_PAGE);
  const [seriesDonut, setSeriesDonut] = useState<number[]>([]);
  const [loadingClean, setLoadingClean] = useState(false);
  const [scoreFilter, setScoreFilter] = useState<number | string>('');
  const [dateCofirmSurvey, setLastCofirmSurvey] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const [startLastCofirmSurvey, endLastCofirmSurvey] = dateCofirmSurvey;
  const formatDate = (date: string) => {
    if (!isDate(date)) return '';

    let newDate = new Date(
      date.indexOf('T') !== -1 ? date.split('T')[0] : date,
    );

    newDate = setDate(newDate, getDate(newDate) + 1);

    return format(newDate, "dd 'de' MMMM", {
      locale: ptBR,
    });
  };
  const optionsBar = {
    grid: {
      show: true,
      borderColor: '#F6F6F6',
      strokeDashArray: 0,
      xaxis: {
        show: true,
        lines: {
          show: true,
        },
        padding: {
          top: 15,
          bottom: 15,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      row: {
        opacity: 0.5,
      },
      column: {
        opacity: 0.5,
        padding: {
          top: 100,
          bottom: 0,
        },
      },
    },

    chart: {
      id: 'bar',
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },
    plotOptions: {
      bar: {
        barHeight: '93%',
        borderRadius: 5,
        distributed: true,
        horizontal: true,
        columnHeight: '20',
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    colors: ['#CDCDCD', '#FFA841', '#FF7DEE', '#FF5071', '#63CEFF', '#40CC79'],
    xaxis: {
      labels: {
        show: false,
      },
      categories: ['0', '1', '2', '3', '4', '5'],
    },
    dataLabels: {
      enabled: true,
      offsetX: 8,
      style: {
        colors: ['#fff'],
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const optionsDonut = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              color: '#373d3f',
              formatter(w: any) {
                return w.globals.seriesTotals.reduce((a: number, b: number) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    labels: [
      'Avaliações',
      'Avaliações',
      'Avaliações',
      'Avaliações',
      'Avaliações',
      'Avaliações',
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff'],
      },
      dropShadow: {
        enabled: false,
      },
    },

    colors: ['#CDCDCD', '#FFA841', '#FF7DEE', '#FF5071', '#63CEFF', '#40CC79'],
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      theme: 'dark',
    },
  };

  const handleGoBack = () => {
    navigate('/customers/notifications-details');
  };
  const getSurveyData = useCallback(
    async (page: number, limit: number, filterData?: SettingsSurveyType) => {
      const { status, msg, data } = await getSurvey(
        groupId,
        notificationId,
        page,
        limit,
        filterData,
      );
      if (status) {
        setSurveys(data);
        const surveyAccumulators = data.surveyAccumulators.map(
          (survey: SurveyAccumulatorsType) => survey.count,
        );
        setSeriesBar(surveyAccumulators);
        setSeriesDonut(surveyAccumulators);
        const countItem = [];
        for (
          let num = 1;
          num <= Math.ceil(data.totalDocs / NOTIFICATIONS_PER_PAGE);
          num += 1
        ) {
          countItem.push(num);
        }
        if (countItem.length <= 0 && data?.surveyReplies.length > 0) {
          setPaginationItem([1]);
        } else setPaginationItem(countItem);
      } else toast.error(msg);
    },
    [groupId, notificationId],
  );

  const cleanFilter = () => {
    setSelectedPage(HOME_PAGE);
    setLoadingClean(true);
    setLastCofirmSurvey([null, null]);
    setScoreFilter('');
    setLoadingClean(false);
    getSurveyData(selectedPage, NOTIFICATIONS_PER_PAGE);
  };

  const handleFilter = (value?: any, endDate?: any) => {
    const data: SettingsSurveyType = {};
    if ((value && typeof value === 'number') || value === 0) {
      data.score = value;
    }
    if (startLastCofirmSurvey)
      data.start_date = startLastCofirmSurvey.toISOString();
    if (endLastCofirmSurvey) data.end_date = endLastCofirmSurvey.toISOString();
    if (endDate) data.end_date = endDate.toISOString();
    setSelectedPage(HOME_PAGE);
    getSurveyData(HOME_PAGE, NOTIFICATIONS_PER_PAGE, data);
  };
  const handleChangeSurvey = (value: number) => {
    setScoreFilter(value);
    handleFilter(value);
  };
  const handleChangeDate = (date: [Date | null, Date | null]) => {
    setScoreFilter('');
    if (date) setLastCofirmSurvey(date);
    if (date[1]) handleFilter(scoreFilter, date[1]);
  };

  useEffect(() => {
    getSurveyData(selectedPage, NOTIFICATIONS_PER_PAGE);
  }, [getSurveyData]);

  const changePage = (page: number) => {
    if (page > 0 && page <= paginationItem.length) {
      setSelectedPage(page);
      const data: any = {};
      const [start, end] = dateCofirmSurvey;
      if (start) {
        data.start_date = start;
      }
      if (end) {
        data.end_date = end;
      }
      if (scoreFilter || scoreFilter === 0) {
        data.score = scoreFilter;
      }
      getSurveyData(page, NOTIFICATIONS_PER_PAGE, data);
    }
  };
  return (
    <Container>
      <HeaderSurvy>
        <NavGoBackContainer onClick={handleGoBack}>
          <GoBackIcon />
          <NavGoBack>Detalhes</NavGoBack>
          {title}
          <NavGoBackName>{title || ''}</NavGoBackName>
        </NavGoBackContainer>
        <Dot /> <LabelId>Relatório das avaliações</LabelId>
      </HeaderSurvy>
      <CardSurvey>
        <ColumnTwelve>
          <Label
            style={{
              display: 'flex',
              marginTop: '10px',
              alignItems: 'baseline',
            }}
          >
            <TitleLabel title="Resumo" />
          </Label>
        </ColumnTwelve>
        <ColumnTwelve>
          <ColumnSix>
            <ReactApexChart
              options={optionsBar}
              series={[{ name: 'number', data: seriesBar }]}
              type="bar"
              height={320}
              width="400"
            />
            <StarSatisfaction />
          </ColumnSix>
          <ColumnSix>
            <ReactApexChart
              style={{ margin: 'auto' }}
              options={optionsDonut}
              series={seriesDonut}
              type="donut"
              height={300}
              width="300"
            />
          </ColumnSix>
        </ColumnTwelve>
        <ColumnTwelve
          style={{
            flexDirection: 'column',
          }}
        >
          <ColumnTwelve>
            <Label
              style={{
                display: 'flex',
                marginTop: '50px',
                alignItems: 'baseline',
              }}
            >
              <Title>Lista de avaliações</Title>
            </Label>
          </ColumnTwelve>

          <ColumnTwelve>
            <ContextFilter>
              <HeaderFilter>
                <FormGroupSurvey>
                  <FormGroup
                    style={{
                      marginLeft: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Label style={{ marginRight: 10 }}>Filtrar por: </Label>
                    <DatePickerStyled
                      selectsRange
                      onInputClick={() => handleFilter()}
                      startDate={startLastCofirmSurvey}
                      endDate={endLastCofirmSurvey}
                      placeholderText="Período de avaliação"
                      onChange={handleChangeDate}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="pt-BR"
                      dateFormat="P"
                      withPortal
                    />
                  </FormGroup>
                </FormGroupSurvey>
                <FormGroupSurvey
                  style={{
                    marginLeft: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Label>Estrelas</Label>
                  <GroupButton>
                    <ButtonStyled
                      variant={scoreFilter === 0 ? 'primary' : 'secondary'}
                      style={{ marginLeft: 10 }}
                      onClick={() => handleChangeSurvey(0)}
                    >
                      0
                    </ButtonStyled>
                    <ButtonStyled
                      variant={scoreFilter === 1 ? 'primary' : 'secondary'}
                      style={{ marginLeft: 3 }}
                      onClick={() => handleChangeSurvey(1)}
                    >
                      1
                    </ButtonStyled>
                    <ButtonStyled
                      variant={scoreFilter === 2 ? 'primary' : 'secondary'}
                      style={{ marginLeft: 3 }}
                      onClick={() => handleChangeSurvey(2)}
                    >
                      2
                    </ButtonStyled>
                    <ButtonStyled
                      variant={scoreFilter === 3 ? 'primary' : 'secondary'}
                      style={{ marginLeft: 3 }}
                      onClick={() => handleChangeSurvey(3)}
                    >
                      3
                    </ButtonStyled>
                    <ButtonStyled
                      variant={scoreFilter === 4 ? 'primary' : 'secondary'}
                      style={{ marginLeft: 3 }}
                      onClick={() => handleChangeSurvey(4)}
                    >
                      4
                    </ButtonStyled>
                    <ButtonStyled
                      variant={scoreFilter === 5 ? 'primary' : 'secondary'}
                      style={{ marginLeft: 3 }}
                      onClick={() => handleChangeSurvey(5)}
                    >
                      5
                    </ButtonStyled>
                  </GroupButton>
                </FormGroupSurvey>
              </HeaderFilter>
              <FooterFilter style={{ marginTop: '1.6rem' }}>
                <ButtonStyled
                  onClick={cleanFilter}
                  variant="secondary"
                  disabled={loadingClean}
                >
                  {loadingClean ? (
                    <LoginSpinner animation="border" variant="light" />
                  ) : (
                    'Limpar'
                  )}
                </ButtonStyled>
              </FooterFilter>
            </ContextFilter>
          </ColumnTwelve>

          <TableStyled
            style={{
              boxShadow: 'none',
            }}
            hover
          >
            <THead>
              <tr>
                <Th>Estrelas</Th>
                <Th>Data</Th>
                <Th>Nome</Th>
              </tr>
            </THead>
            <tbody>
              {surveys?.surveyReplies.map(survey => (
                <Tr>
                  <Td>{`${survey.score} estrelas`}</Td>
                  <Td>{formatDate(survey.createdAt)}</Td>
                  <Td>{survey.id_customer.name}</Td>
                </Tr>
              ))}
            </tbody>
            {surveys && surveys?.surveyReplies?.length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan={100}>
                    <PaginationStyled size="sm">
                      <Pagination.First onClick={() => changePage(HOME_PAGE)} />
                      <Pagination.Prev
                        onClick={() => changePage(selectedPage - 1)}
                      />
                      {selectedPage > 1 && (
                        <Pagination.Item
                          key={selectedPage - 1}
                          onClick={() => changePage(selectedPage - 1)}
                        >
                          {selectedPage - 1}
                        </Pagination.Item>
                      )}
                      <Pagination.Item
                        key={selectedPage}
                        active
                        onClick={() => changePage(selectedPage)}
                      >
                        {selectedPage}
                      </Pagination.Item>
                      {selectedPage < paginationItem.length &&
                        surveys.surveyReplies.length === 10 &&
                        paginationItem.length > 10 && (
                          <>
                            <Pagination.Item
                              key={selectedPage + 1}
                              onClick={() => changePage(selectedPage + 1)}
                            >
                              {selectedPage + 1}
                            </Pagination.Item>
                            <Pagination.Next
                              onClick={() => changePage(selectedPage + 1)}
                            />
                            <Pagination.Last
                              onClick={() => changePage(paginationItem.length)}
                            />
                          </>
                        )}
                    </PaginationStyled>
                  </td>
                </tr>
              </tfoot>
            )}
          </TableStyled>
        </ColumnTwelve>
      </CardSurvey>
    </Container>
  );
};

export default ResultsSatisfactionSurveys;
