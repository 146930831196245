/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PartnersType from '../../../types/partners.type';

export interface PartnerState {
  partner: PartnersType;
}

const initialState: PartnerState = {
  partner: {
    _id: '',
    id_group: '',
    banner_url: '',
    description: '',
    partner_categories: [''],
    phone_number: '',
    slogan: '',
    status: '',
    title: '',
  },
};

export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    selectPartner: (state, action: PayloadAction<PartnersType>) => {
      state.partner = action.payload;
    },
  },
});

export const { selectPartner } = partnerSlice.actions;

export default partnerSlice.reducer;
