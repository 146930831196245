import styled from 'styled-components';

export const DividerFormStyled = styled.div`
  width: 90%;
  border-bottom: 1px;
  border-color: #f5f5f5;
  border-width: 2px;
  border-bottom-style: solid;
  margin-bottom: 25px;
`;
export const Divider = styled.div`
  cursor: pointer;
`;
