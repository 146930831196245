/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { registerLocale } from 'react-datepicker';
import { format, getDate, setDate } from 'date-fns';
import makeAnimated from 'react-select/animated';
import MultiSelect, { StylesConfig } from 'react-select';
import ptBR from '../../utils/locale';
import isDate from '../../utils/isDate';
import SkeletonList from '../../Components/SkeletonList';
import TitlePage from '../../Components/TitlePage';
import Flag from '../../Components/Flag';
import { theme } from '../../global/styles/theme';

import { ReactComponent as SearchIcon } from '../../assets/svgs/search.svg';
// import { ReactComponent as CalenderIcon } from '../../assets/svgs/calender.svg';

import {
  Container,
  Context,
  Header,
  ButtonFilter,
  InputSearchStyled,
  PaginationStyled,
  TableStyled,
  THead,
  Th,
  Td,
  Tr,
  ContextFilter,
  FormGroup,
  Label,
  GroupButton,
  ButtonStyled,
  FooterFilter,
  HeaderFilter,
  ArrowDownIconStyled,
  ArrowTopIconStyled,
  LoginSpinner,
  AlertTable,
  AreaPagination,
  DescriptionPagination,
} from './styles';

import { useAppDispatch, useAppSelector } from '../../store';
import { selectPartner } from '../../store/slices/Partner/Partner.slice';

import {
  getPartners,
  getPartnerCategory,
} from '../../services/Partners.service';

import PartnersType from '../../types/partners.type';
import FilterType from '../../types/partnerAdvancedFilter.type';
import PartnerCategoriesType from '../../types/partnerCategories.type';

import 'react-datepicker/dist/react-datepicker.css';

interface PartnerCategoriesProps {
  value: string;
  label: string;
  isFixed?: boolean;
}

const Partners: React.FC = () => {
  const PARTNERS_PER_PAGE = 15;
  const HOME_PAGE = 1;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [partners, setPartners] = useState<PartnersType[]>([]);
  const [allPartners, setAllPartners] = useState<PartnersType[]>([]);
  const [paginationItem, setPaginationItem] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(HOME_PAGE);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPartners, setTotalPartners] = useState<number>(0);
  const [loadingClean, setLoadingClean] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState(false);
  const [filterStatus, setFilterStatus] = useState('init');
  const [descriptionPagination, setDescriptionPagination] = useState('');
  const [pageInit, setPageInit] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [partnerCategories, setPartnerCategories] =
    useState<PartnerCategoriesProps[]>();
  const [partnerCategoriesSelected, setPartnerCategoriesSelected] = useState<
    string[]
  >([]);

  const companies = useAppSelector(
    state => state.companyReducer.companySelected,
  );

  const partnersInital: PartnersType = {
    id_group: '',
    partner_categories: [''],
    title: '',
    slogan: '',
    description: '',
    banner_url: '',
    phone_number: '',
    status: '',
  };

  const animatedComponents = makeAnimated();

  const colourStyles: StylesConfig = {
    container: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      borderWidth: 0.5,
      borderStyle: 'solid',
      borderColor: theme.colors.borderInput,
    }),
    control: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      borderWidth: 0.5,
      borderStyle: 'none',
      ':hover': {
        ...styles[':hover'],
        borderColor: theme.colors.primary,
        boxShadow: `0 0 0 1px ${theme.colors.primary}`,
      },
    }),
  };

  const getPartnersCotegories = useCallback(async () => {
    const { data, status, msg } = await getPartnerCategory(companies.id_group);

    if (!status) toast.error(msg);
    else {
      setPartnerCategories(
        data.map((partnerC: PartnerCategoriesType) => {
          return {
            value: partnerC._id,
            label: partnerC.name,
          };
        }),
      );
    }
  }, [companies.id_group]);

  useEffect(() => {
    getPartnersCotegories();
  }, [getPartnersCotegories]);

  const getAllPartners = useCallback(async () => {
    const { data, status, msg } = await getPartners(
      companies.id_group,
      totalPartners,
      HOME_PAGE,
    );

    if (!status) toast.error(msg);
    else setAllPartners(data?.partners);
  }, [companies.id_group, totalPartners]);

  const getPartnersData = useCallback(
    async (limit: number, page: number, filter?: FilterType) => {
      const { data, status, msg } = await getPartners(
        companies.id_group,
        limit,
        page,
        filter || undefined,
      );

      if (!status) {
        toast.error(msg);
        setLoading(false);
        setLoadingClean(false);
      } else {
        setPartners(data?.partners);
        setTotalPartners(data?.totalDocs);

        const countItem = [];
        for (
          let num = 1;
          num <= Math.ceil(data.totalDocs / PARTNERS_PER_PAGE);
          num += 1
        ) {
          countItem.push(num);
        }

        if (countItem.length <= 0 && data?.partners.length > 0)
          setPaginationItem([1]);
        else setPaginationItem(countItem);
        setLoading(false);
        setLoadingClean(false);
      }
    },
    [companies.id_group],
  );

  const changePage = (page: number) => {
    if (page > 0 && page <= paginationItem.length) {
      if (page > 5) {
        setPageInit(page - 5);
        setPageLimit(page);
      } else {
        setPageInit(0);
        setPageLimit(5);
      }

      setSelectedPage(page);
      getPartnersData(PARTNERS_PER_PAGE, page);
    }
  };

  const findValue = (value: string, find: string) => {
    return value.toLowerCase().indexOf(find.toLowerCase()) !== -1;
  };

  const formatDate = (date: string) => {
    if (!isDate(date)) return '';

    let newDate = new Date(
      date.indexOf('T') !== -1 ? date.split('T')[0] : date,
    );

    newDate = setDate(newDate, getDate(newDate) + 1);

    return format(newDate, "dd 'de' MMMM", {
      locale: ptBR,
    });
  };

  const filterPartners = (value: string) => {
    setSearch(value);

    if (!value) return getPartnersData(PARTNERS_PER_PAGE, HOME_PAGE);

    const data = allPartners.filter((partner: PartnersType) => {
      if (!partner.title) return false;

      const title = findValue(partner.title, value);

      return title;
    });

    if (!data.length) return setPartners([]);

    setPartners(data);

    return setPaginationItem([HOME_PAGE]);
  };

  const partnerAdvancedFilter = () => {
    if (!loadingClean) setLoading(true);

    const data: FilterType = {};

    if (filterStatus !== 'init') data.status = filterStatus;

    if (partnerCategoriesSelected && partnerCategoriesSelected.length)
      data.partner_categories = partnerCategoriesSelected;

    if (!Object.keys(data).length) {
      setLoading(false);
      return toast.warning('Selecione uma opção para filtrar um parceiro');
    }

    return getPartnersData(PARTNERS_PER_PAGE, HOME_PAGE, data);
  };

  const cleanFilter = () => {
    setLoadingClean(true);
    setFilterStatus('init');
    getPartnersData(PARTNERS_PER_PAGE, HOME_PAGE);
  };

  const handleViewPartnerDetail = (partner: PartnersType) => {
    dispatch(selectPartner(partner));
    navigate('/customers/partners/partners-details', {
      state: {
        partnerId: partner._id,
        partnerCategories,
        partnerCategoriesId: partner.partner_categories,
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    registerLocale('pt-BR', ptBR);
    getPartnersData(PARTNERS_PER_PAGE, HOME_PAGE);
  }, [getPartnersData]);

  const changeAdvancedFilter = () => {
    setAdvancedFilter(!advancedFilter);
    if (advancedFilter) getPartnersData(PARTNERS_PER_PAGE, HOME_PAGE);
  };

  useEffect(() => {
    setDescriptionPagination(
      `${
        (selectedPage - 1) * PARTNERS_PER_PAGE +
        partners.length -
        (partners.length - 1)
      }-${
        (selectedPage - 1) * PARTNERS_PER_PAGE + partners.length
      } de ${totalPartners} Campanhas`,
    );
  }, [partners.length, selectedPage, totalPartners]);

  return (
    <Container>
      <TitlePage title="Lista de parceiros" />
      <Context>
        {loading && <SkeletonList />}
        {!loading && (
          <>
            <Header>
              <InputSearchStyled
                icon={SearchIcon}
                name="search"
                type="text"
                placeholder="Pesquisar por parceiro"
                value={search}
                onChange={e => filterPartners(e.target.value)}
                onFocus={getAllPartners}
              />
              <ButtonFilter variant="secondary" onClick={changeAdvancedFilter}>
                Avançado
                {advancedFilter ? (
                  <ArrowTopIconStyled />
                ) : (
                  <ArrowDownIconStyled />
                )}
              </ButtonFilter>
              <ButtonFilter
                style={{ marginLeft: 'auto' }}
                variant="primary"
                onClick={() => handleViewPartnerDetail(partnersInital)}
              >
                Adicionar parceiro
              </ButtonFilter>
            </Header>
            {advancedFilter && (
              <ContextFilter>
                <HeaderFilter>
                  <FormGroup sm={4} md={3} xl={3}>
                    <Label>Status</Label>
                    <GroupButton>
                      <ButtonStyled
                        onClick={() => setFilterStatus('active')}
                        variant={
                          filterStatus === 'active' ? 'primary' : 'secondary'
                        }
                      >
                        Ativo
                      </ButtonStyled>
                      <ButtonStyled
                        onClick={() => setFilterStatus('inactive')}
                        variant={
                          filterStatus === 'inactive' ? 'primary' : 'secondary'
                        }
                      >
                        Inativo
                      </ButtonStyled>
                    </GroupButton>
                  </FormGroup>
                  <FormGroup sm={12} md={8} xl={8}>
                    <Label>Categorias</Label>
                    <MultiSelect
                      theme={(themeSelect: any) => ({
                        ...themeSelect,
                        colors: {
                          ...themeSelect.colors,
                          primary: theme.colors.primary,
                        },
                      })}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Selecione as categorias"
                      styles={colourStyles}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={partnerCategories}
                      onChange={(e: any) =>
                        setPartnerCategoriesSelected(
                          e.map(
                            (partnerCategoryTmp: PartnerCategoriesProps) =>
                              partnerCategoryTmp.value,
                          ),
                        )
                      }
                    />
                  </FormGroup>
                </HeaderFilter>
                <FooterFilter>
                  <ButtonStyled
                    onClick={cleanFilter}
                    variant="secondary"
                    disabled={loadingClean}
                  >
                    {loadingClean ? (
                      <LoginSpinner animation="border" variant="light" />
                    ) : (
                      'Limpar'
                    )}
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={partnerAdvancedFilter}
                    disabled={loading}
                  >
                    {loading ? (
                      <LoginSpinner animation="border" variant="light" />
                    ) : (
                      'Aplicar'
                    )}
                  </ButtonStyled>
                </FooterFilter>
              </ContextFilter>
            )}
            <TableStyled hover>
              <THead>
                <tr>
                  <Th>Título</Th>
                  <Th>Cadastro</Th>
                  <Th>Status</Th>
                </tr>
              </THead>
              <tbody>
                {partners.map((partner: PartnersType) => (
                  <Tr
                    key={partner._id}
                    onClick={() => handleViewPartnerDetail(partner)}
                  >
                    <Td style={{ width: '35%' }}>{partner.title}</Td>
                    <Td>{formatDate(partner.createdAt || '')}</Td>
                    <Td>
                      <Flag
                        text={
                          partner.status && partner.status === 'inactive'
                            ? 'Inativo'
                            : 'Ativo'
                        }
                        status={
                          partner.status && partner.status === 'inactive'
                            ? 'error'
                            : 'success'
                        }
                      />
                    </Td>
                  </Tr>
                ))}
              </tbody>
              {partners.length > 0 && (
                <tfoot>
                  <tr>
                    <td colSpan={5}>
                      <AreaPagination>
                        <DescriptionPagination style={{ visibility: 'hidden' }}>
                          {descriptionPagination}
                        </DescriptionPagination>
                        <PaginationStyled size="sm">
                          <Pagination.First
                            onClick={() => changePage(HOME_PAGE)}
                          />
                          <Pagination.Prev
                            onClick={() => changePage(selectedPage - 1)}
                          />
                          {paginationItem
                            .slice(pageInit, pageLimit)
                            .map((page: number) => (
                              <Pagination.Item
                                key={page}
                                active={page === selectedPage}
                                onClick={() => changePage(page)}
                              >
                                {page}
                              </Pagination.Item>
                            ))}
                          <Pagination.Next
                            onClick={() => changePage(selectedPage + 1)}
                          />
                          <Pagination.Last
                            onClick={() => changePage(paginationItem.length)}
                          />
                        </PaginationStyled>
                        <DescriptionPagination>
                          {descriptionPagination}
                        </DescriptionPagination>
                      </AreaPagination>
                    </td>
                  </tr>
                </tfoot>
              )}
            </TableStyled>
            {partners.length <= 0 && (
              <AlertTable>Nenhum cliente encontrado</AlertTable>
            )}
          </>
        )}
      </Context>
    </Container>
  );
};

export default Partners;
