import styled from 'styled-components';
import { Table, Pagination, Button, Spinner } from 'react-bootstrap';

import { theme } from '../../../global/styles/theme';

const { colors } = theme;

export const Container = styled.div`
  width: 100%;
  margin: 4.5rem auto;
  padding-left: 8rem;
  padding-right: 1.6rem;
`;

export const Context = styled.div`
  width: 100%;
  margin: 2rem auto;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @media (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
`;

export const ButtonFilter = styled(Button)`
  margin-left: 0.6rem;
  border-radius: 10px;

  @media (max-width: 550px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
`;

export const TableStyled = styled(Table)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: ${colors.white};

  border-collapse: separate;
  border-spacing: 0;
`;

export const THead = styled.thead`
  color: ${colors.text.tHead};
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  border-bottom-color: ${colors.background};
`;

export const Th = styled.th`
  padding: 1rem 0.5rem !important;
  padding-left: 1rem !important;
`;

export const Td = styled.td`
  padding-left: 1rem !important;
  color: ${colors.text.title};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
`;

export const Tr = styled.tr`
  cursor: pointer;
`;

export const PaginationStyled = styled(Pagination)`
  margin: 0.6rem 0;
  margin-right: 0.6rem;
  justify-content: flex-end;
`;

export const LoginSpinner = styled(Spinner)`
  height: 1.4rem;
  width: 1.4rem;
`;

export const AlertTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 0.8rem;
  background: ${colors.flag.error.background};
  color: ${colors.flag.error.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const AreaPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DescriptionPagination = styled.span`
  font-size: 12px;
`;
