import styled from 'styled-components';
import {
  Button,
  Spinner,
  Pagination,
  ButtonGroup,
  Form,
  Table,
  Row,
  Col,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { theme } from '../../../global/styles/theme';
import Input from '../../../Components/Input';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/svgs/arrow-left.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/svgs/arrow-down.svg';
import { ReactComponent as HelpIcon } from '../../../assets/svgs/help.svg';
import { ReactComponent as starsurvey } from '../../../assets/svgs/starsurvey.svg';

const { colors } = theme;

export const Container = styled.div`
  width: 100%;
  margin: 4.5rem auto;
  padding-left: 8rem;
  padding-right: 1.6rem;
`;

export const Context = styled.div`
  display: flex;
  width: 100%;
  margin: 2rem auto;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 1.2rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderSurvy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const NavGoBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const NavGoBack = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 0;
  color: ${colors.text.tHead};
`;

export const LabelId = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 0;
  color: ${colors.text.tHead};
`;

export const NavGoBackName = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  color: ${colors.text.title};
`;

export const GoBackIcon = styled(ArrowLeftIcon)`
  margin-right: 1rem;
`;

export const StarSatisfaction = styled(starsurvey)`
  margin-left: 1rem;
  margin-top: 5.3rem;
`;

export const HelpIconStyled = styled(HelpIcon)`
  margin-left: 0.5rem;
  width: auto;
  height: 0.8rem;
`;

export const Dot = styled.div`
  border-radius: 50%;
  background-color: ${colors.text.tHead};
  height: 6px;
  width: 6px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const RowForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 44rem;
  width: 100%;
  margin-bottom: 2rem;
`;

export const InputStyled = styled(Input)`
  max-width: 44rem;
  width: 100%;
  margin-top: 0.6rem;
`;

export const Textarea = styled(Input)`
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
  min-height: 2.4rem;
  background-color: ${colors.white};
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: ${colors.text.title};
  font-weight: 400;
  font-size: 13px;
  border: 1px solid ${colors.borderInput};
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 44rem;
  width: 100%;
  margin-top: 0.6rem;
  padding: 0.5rem;
`;

export const Select = styled(Form.Select)`
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
  min-height: 2.4rem;
  background-color: ${colors.white};
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: ${colors.text.title};
  font-weight: 400;
  font-size: 13px;
  border: 1px solid ${colors.borderInput};
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 44rem;
  width: 100%;
  margin-top: 0.6rem;
`;

export const ButtonGroupStyled = styled(ButtonGroup)`
  margin-top: 0rem;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
`;
export const Qtynumber = styled.span`
  background: #fff;
  padding: 1px 8px;
  border-radius: 50px;
  font-weight: 600;
`;
export const ButtonContainer = styled.div`
  display: block;

  @media (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
`;

export const ButtonQtd = styled(Button)`
  margin-left: 0.6rem;
  border-radius: 10px;

  @media (max-width: 550px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
`;

export const ButtonStyled = styled(Button)`
  margin-left: 0rem;
  border-radius: 10px;

  @media (max-width: 550px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
`;

export const LoginSpinner = styled(Spinner)`
  height: 1.4rem;
  width: 1.4rem;
`;

export const GroupButton = styled.div`
  margin-top: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ColumnFour = styled.div`
  display: flex;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
  width: 32.5%;
`;

export const ColumnSix = styled.div`
  display: flex;
  flex: 0 0 49%;
`;

export const ColumnTwelve = styled.div`
  display: flex;

  justify-content: normal;
  align-items: normal;
  align-content: normal;
  width: 100%;
`;

export const ContextFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0.1rem;
  margin-bottom: 2rem;
  background: #f7f7f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  :start {
    transition-delay: 1s;
  }
`;

export const FormGroupSurvey = styled.div`
  width: 100%;
  max-width: 340px;
  align-items: center;
  display: flex;
`;

export const HeaderFilter = styled(Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;

  @media (max-width: 900px) {
    justify-content: space-around;
    margin-top: 0.6rem;
  }

  @media (max-width: 935px) {
    margin-top: 0.8rem;
  }
`;

export const FormGroup = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0.8rem;

  @media (max-width: 1040px) {
    margin: 1rem;
    margin-top: 0.8rem;
  }

  @media (max-width: 1000px) {
    margin: 1.2rem;
    margin-top: 0.6rem;
  }

  @media (max-width: 975px) {
    margin: 1.5rem;
    margin-top: 0.5rem;
  }

  @media (max-width: 935px) {
    margin: 1.8rem;
    margin-top: 0.3rem;
  }

  @media (max-width: 900px) {
    margin: 2rem;
    margin-top: 0.2rem;
  }

  .react-datepicker__close-icon {
    position: inherit;
    padding: 0;
    margin-left: 0.4rem;
  }

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
  }

  .react-datepicker__close-icon::after {
    background-color: ${theme.colors.primary};
    vertical-align: initial;
    height: 18px;
    width: 18px;
  }
`;

export const ButtonFilter = styled(Button)`
  margin-left: 0.6rem;
  border-radius: 10px;
  color: ${colors.text.title};
  background-color: #cccccc;
  border-color: #cccccc;
`;

export const ArrowDownIconStyled = styled(ArrowDownIcon)`
  margin-left: 0.5rem;
`;

export const ArrowTopIconStyled = styled(ArrowDownIcon)`
  margin-left: 0.5rem;
  transform: rotate(180deg);
`;

export const CardSurvey = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin: 1rem auto;
  margin-bottom: 0.3rem;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 1.5rem 1.5rem;
  align-items: center;
`;

export const CardSurveyNotification = styled.div`
  margin: 0;
  transition: 0.4s;
  &:hover {
    transition: 0.4s;
    margin-top: -3px;
    margin-bottom: 3px;
  }
  &:focus {
    transition: 0.4s;
    margin-top: -3px;
    margin-bottom: 3px;
  }
`;

export const UserTotalIcon = styled.div`
  height: 85px;
  width: 85px;
  background-size: 85px;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 3rem;
`;

export const TitleTableTotal = styled.div`
  margin-top: 3rem;
  padding-top: 1rem;
  padding-left: 0.2rem;
  display: flex;
`;
export const Qtylabel = styled.div`
  background-color: #dcdaf0;
  color: #4e47b9;
  margin-left: 10px;
  padding: 7px 10px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 13px;
`;
export const Totallabel = styled.div`
  background-color: #fdf5ea;
  color: #e99717;
  margin-left: 5px;
  padding: 7px 10px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 13px;
`;

export const UserIcon = styled.div`
  height: 40px;
  width: 40px;
  background-size: 40px;
  background-repeat: no-repeat;
  margin-right: 11px;
  margin-top: -5px;
`;

export const LabelButton = styled.div`
  background: #fff;
  border-radius: 100px;
  padding: 10px 30px;
  cursor: pointer;
  &:hover {
    background: #6f549f;
    color: #fff;
    transition: 0.4s;
  }
  &:focus {
    background: #6f549f;
    color: #fff;
    transition: 0.4s;
  }
`;

export const CardBackgroundEffect = styled.div`
  background: linear-gradient(-45deg, #6f549f, #ac3ba1, #664c94, #9324c8);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin: 1rem auto;
  margin-bottom: 0.1rem;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 1.2rem;
  padding-bottom: 0px;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const TotalNumber = styled.div`
  margin: auto;
  padding: 1.2rem;
  font-size: 3.5rem;
  font-weight: 600;
  color: #fff;
`;

export const TotalNumberRow = styled.div`
  font-size: 2rem;
  font-weight: 500;
  color: #333;
  width: 20%;
`;

export const TableStyled = styled(Table)`
  border-collapse: separate;
  border-spacing: 0;
`;

export const TableDashboard = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: ${colors.white};
  border-collapse: separate;
  border-spacing: 0;
`;

export const FooterFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

export const THead = styled.thead`
  color: ${colors.text.tHead};
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  border-bottom-color: ${colors.background};
`;

export const TitleLabel = styled.h1`
  color: #333333;
  font-size: 1rem;

  font-weight: 400;
`;
export const TitleLabelTable = styled.h1`
  color: #333333;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 2px;
  margin-left: 10px;
`;
export const Th = styled.th`
  padding: 1rem 0.5rem !important;
  padding-left: 1rem !important;
`;

export const Td = styled.td`
  padding-left: 1rem !important;
  color: ${colors.text.title};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
`;

export const Tr = styled.tr`
  cursor: pointer;
`;

export const Title = styled.h1`
  color: rgb(141 90 188);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

export const PaginationStyled = styled(Pagination)`
  margin: 0.6rem 0;
  margin-right: 0.6rem;
  justify-content: flex-end;
`;
export const DatePickerStyled = styled(DatePicker)`
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
  max-width: 44rem;
  width: 100%;
  min-height: 2.4rem;
  background-color: ${colors.white};
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: ${colors.text.title};
  font-weight: 400;
  font-size: 13px;
  border: 1px solid ${colors.borderInput};
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 0.6rem;

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::placeholder,
  &:-ms-input-placeholder {
    color: ${colors.background};
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border: 1px solid ${colors.primary};
    box-shadow: none;
  }
`;

export const EmptyListIcon = styled.div`
  height: 250px;
  width: 250px;
  background-size: 250px;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-position: center;
  padding: 9.9rem 0px;
`;
