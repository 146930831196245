import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
*:focus {
  outline: 0;
}
html, body, #root {
  height: 100%;
  background: ${theme.colors.background};
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
}
`;
