import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../store';
import { signOut } from '../store/slices/SignIn/SignIn.slice';
import { removeCompany } from '../store/slices/Company/Company.slice';
import api from '../services/api';

import Layout from '../pages/_layouts/default';

interface PrivateRouteProps {
  isLogged: boolean;
  token: string;
}

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const PrivateRoute = ({ isLogged, token }: PrivateRouteProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const company = useAppSelector(state => state.companyReducer.companySelected);

  if (token) {
    const decodedJwt = parseJwt(token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      dispatch(signOut());
      dispatch(removeCompany());
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  if (!isLogged) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!company.cnpj) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoute;
