import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ReactComponent as InputUser } from '../../assets/svgs/input-user.svg';
import { ReactComponent as InputPsw } from '../../assets/svgs/input-psw.svg';

import LoginLogo from '../../assets/images/login-logo.png';
import LoginBanner from '../../assets/images/login-banner.png';

import {
  Container,
  Layout,
  Login,
  LoginWrapContent,
  LoginBody,
  LogoView,
  EnterTitle,
  EnterDescription,
  LoginForm,
  ButtonsRow,
  ShowPasswordContainer,
  ShowPasswordTitle,
  ShowPasswordCheck,
  EnterButton,
  ErrorMessage,
  LoginSpinner,
  LoginFooter,
  Banner,
  BannerImg,
  InputOutlinedStyled,
  InputContent,
} from './styles';
import { useAppDispatch, useAppSelector } from '../../store';
import { signInRequest } from '../../store/slices/SignIn/SignIn.slice';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('O Email é obrigatório'),
  password: Yup.string().required('A Senha é obrigatória'),
});

const SignIn: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(state => state.signInReducer.loading);
  const signed = useAppSelector(state => state.signInReducer.signed);
  const returnErrorMsg = useAppSelector(state => state.signInReducer.msg);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const data = { email, password };

    const isValid = await schema
      .validate(data)
      .catch(({ errors }) => errors[0]);

    if (!Object.is(isValid, data)) return setErrorMsg(isValid);

    setErrorMsg('');

    return dispatch(signInRequest(data));
  };

  useEffect(() => {
    if (!loading && signed) {
      navigate('/', { replace: true });
    }
  }, [loading, navigate, signed]);

  return (
    <Container>
      <Layout>
        <Login>
          <LoginWrapContent>
            <LoginBody>
              <LogoView src={LoginLogo} />
              <EnterTitle>Entrar</EnterTitle>
              <EnterDescription>
                Olá! Faça seu login, e tenha acesso completo a solução para o
                segmento Death Care.
              </EnterDescription>
              <LoginForm onSubmit={handleSubmit}>
                <InputContent>
                  <InputOutlinedStyled
                    icon={InputUser}
                    name="email"
                    type="text"
                    placeholder="E-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </InputContent>
                <InputContent>
                  <InputOutlinedStyled
                    icon={InputPsw}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Senha"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </InputContent>
                <ButtonsRow>
                  <ShowPasswordContainer>
                    <ShowPasswordTitle>Mostrar senha</ShowPasswordTitle>
                    <ShowPasswordCheck
                      id="showPassword"
                      type="switch"
                      value={showPassword}
                      onChange={() => setShowPassword(!showPassword)}
                    />
                  </ShowPasswordContainer>
                  <EnterButton type="submit" disabled={loading}>
                    {loading ? (
                      <LoginSpinner animation="border" variant="light" />
                    ) : (
                      'Entrar'
                    )}
                  </EnterButton>
                </ButtonsRow>

                <ErrorMessage>{errorMsg || returnErrorMsg}</ErrorMessage>
              </LoginForm>
            </LoginBody>
            <LoginFooter>
              Plataforma Virtus 2022 © Todos os direitos reservados.
            </LoginFooter>
          </LoginWrapContent>
        </Login>
        <Banner>
          <BannerImg src={LoginBanner} />
        </Banner>
      </Layout>
    </Container>
  );
};

export default SignIn;
