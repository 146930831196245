/* eslint-disable default-case */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { compareAsc, compareDesc, set } from 'date-fns';

import {
  getAdvert,
  deleteAdvert,
  createAdvert,
  updateAdvert,
  sendImageSpaces,
} from '../../../../services/Adverts.service';
import { useAppSelector } from '../../../../store';
import FloatFooter from '../../../../Components/FloatFooter';
import AdsExample from '../../../../Components/AdsExample';
import FileUpload from '../../../../Components/FileUpload';
import '../../../../custom.scss';

import {
  Container,
  Dot,
  GoBackIcon,
  NavGoBack,
  ButtonFilter,
  NavGoBackContainer,
  NavGoBackName,
  Context,
  InputStyled,
  RowForm,
  Label,
  ButtonContainer,
  ButtonStyled,
  Header,
  LabelId,
  LoginSpinner,
  IconPlay,
  GroupButton,
  DatePickerStyled,
  Textarea,
} from './styles';
import AdvertsType from '../../../../types/adverts.type';

const NotificationsDetails = () => {
  const navigate = useNavigate();
  const { state }: { state: any } = useLocation();
  const { advertId } = state || {
    advertId: '',
  };

  const [today] = useState(new Date());
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buttunUrl, setButtunUrl] = useState('');
  const [buttunText, setButtunText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [image, setImage] = useState<FormData>();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [titleButton, setTitleButton] = useState('Adicionar');
  const [statusAdvert, setStatusAdvert] = useState('inactive');
  const [periodBetweenDates, setPeriodBetweenDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const [startPeriodBetweenDates, endPeriodBetweenDates] = periodBetweenDates;

  const company = useAppSelector(
    stateSelector => stateSelector.companyReducer.companySelected,
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleGoBack = () => {
    navigate('/customers/adverts');
  };

  const getAdvertOne = useCallback(async () => {
    const { data, status, msg } = await getAdvert(advertId);

    if (!status) toast.error(msg);

    setTitle(data.title);
    setDescription(data.description);
    setButtunUrl(data.action_button_url);
    setButtunText(data.action_button_text);
    setPeriodBetweenDates([new Date(data.date_from), new Date(data.date_to)]);
    setImageUrl(data.media_url);
    setStatusAdvert(data.status);
  }, [advertId]);

  useEffect(() => {
    if (advertId) {
      setTitleButton('Atualizar');
      getAdvertOne();
    }
  }, [getAdvertOne, advertId]);

  const schema = Yup.object().shape({
    title: Yup.string().required('O título é obrigatório'),
    description: Yup.string().required('A descrição é obrigatório'),
    date_from: Yup.date().required('O período é obrigatório'),
    date_to: Yup.date().required('O período é obrigatório'),
    media_url: Yup.string(),
    status: Yup.string(),
    id_group: Yup.string(),
    action_button_text: Yup.string().required(
      'O texto do botão de ação é obrigatório',
    ),
    action_button_url: Yup.string()
      .url('Deve ser uma URL válida')
      .required('A URL do botão de ação é obrigatória'),
  });

  const sendImage = async (advertsId: string) => {
    if (!image) return '';

    const { status, msg, data } = await sendImageSpaces(
      company.id_group,
      advertsId,
      image,
    );

    if (!status) {
      toast.error(msg);
      return '';
    }

    return data.img.url;
  };

  const handleAddAdvert = async () => {
    const values: AdvertsType = {
      title,
      description,
      action_button_text: buttunText,
      action_button_url: buttunUrl,
      id_group: company.id_group,
      status: statusAdvert,
      // media_url: await sendImage(),
      date_from: periodBetweenDates[0] || undefined,
      date_to: periodBetweenDates[1] || undefined,
    };

    const isValid = await schema
      .validate(values)
      .catch(({ errors }) => errors[0]);

    if (!Object.is(isValid, values)) {
      setLoading(false);
      return toast.error(isValid);
    }

    const {
      status: statusCreate,
      msg: msgCreate,
      data,
    } = await createAdvert(values);

    if (!statusCreate) {
      setLoading(false);
      return toast.error(msgCreate);
    }

    values.media_url = await sendImage(data._id);

    const { status } = await updateAdvert(data._id, values);

    setLoading(false);

    if (!status)
      toast.error(
        'Não foi possível adicionar a imagem ao campanha. Atualize o campanha adicionando a imagem.',
      );

    toast.success('Campanha adicionada com sucesso!');

    return handleGoBack();
  };

  const handleUpdateNotification = async () => {
    const values: AdvertsType = {
      title,
      description,
      action_button_text: buttunText,
      action_button_url: buttunUrl,
      id_group: company.id_group,
      status: statusAdvert,
      media_url: image ? await sendImage(advertId) : imageUrl,
      date_from: periodBetweenDates[0] || undefined,
      date_to: periodBetweenDates[1] || undefined,
    };

    const isValid = await schema
      .validate(values)
      .catch(({ errors }) => errors[0]);

    if (!Object.is(isValid, values)) {
      setLoading(false);
      return toast.error(isValid);
    }

    const { status, msg } = await updateAdvert(advertId, values);

    setLoading(false);
    if (!status) return toast.error(msg);

    toast.success('Campanha atualizado com sucesso!');

    return handleGoBack();
  };

  const handleDeleteNotification = async () => {
    const { status, msg } = await deleteAdvert(advertId);

    if (!status) return toast.error(msg);

    toast.success('Campanha deletado com sucesso!');
    return handleGoBack();
  };

  const handleSubmit = () => {
    setLoading(true);
    if (advertId) return handleUpdateNotification();
    return handleAddAdvert();
  };

  const handleChangeImage = (img: FormData) => {
    setImage(img);
  };

  const isExpiredDate = (dateFrom: Date | null, dateTo: Date | null) => {
    if (!dateFrom || !dateTo) return false;

    const compareDateFrom = compareAsc(
      set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
      dateFrom,
    );
    const compareDateTo = compareDesc(
      set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
      dateTo,
    );

    return compareDateFrom !== -1 && compareDateTo !== -1;
  };

  const handlestatusAdvert = () => {
    if (statusAdvert === 'active') setStatusAdvert('inactive');
    else if (isExpiredDate(periodBetweenDates[0], periodBetweenDates[1]))
      setStatusAdvert('active');
    else toast.error('Adicione um período da campanha valido');
  };

  return (
    <>
      <FloatFooter>
        <ButtonContainer>
          {advertId && (
            <ButtonStyled variant="secondary" onClick={handleShow}>
              Deletar
            </ButtonStyled>
          )}
          <ButtonStyled
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <LoginSpinner animation="border" variant="light" />
            ) : (
              titleButton
            )}
          </ButtonStyled>
        </ButtonContainer>
      </FloatFooter>
      <Container>
        <Row>
          <Col xs={12}>
            <Header>
              <NavGoBackContainer onClick={handleGoBack}>
                <GoBackIcon />
                <NavGoBack>Lista</NavGoBack>
                {title && <Dot />}
                <NavGoBackName>{title || ''}</NavGoBackName>
              </NavGoBackContainer>
              {advertId && <LabelId>{`ID: ${advertId}`}</LabelId>}
            </Header>
          </Col>
        </Row>
        <Row>
          <Col>
            <Context>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonFilter variant="secondary" onClick={handlestatusAdvert}>
                  <IconPlay />
                  {statusAdvert === 'active' ? 'Desativar' : 'Ativar'}
                </ButtonFilter>
              </div>
              <RowForm>
                <Label>Título da campanha</Label>
                <InputStyled
                  name="name"
                  type="text"
                  value={title}
                  onChange={(e: any) => setTitle(e.target.value)}
                />
              </RowForm>
              <RowForm>
                <Label>Descrição</Label>
                <Textarea
                  name="descricao"
                  as="textarea"
                  placeholder="Escreva uma descrição..."
                  rows={6}
                  value={description}
                  onChange={(e: any) => setDescription(e.target.value)}
                />
              </RowForm>
              <RowForm>
                <Label>Mídia</Label>
                <FileUpload
                  onChangeFile={handleChangeImage}
                  urlImage={imageUrl}
                />
              </RowForm>
              <RowForm>
                <Label>Texto botão de ação</Label>
                <GroupButton>
                  <ButtonStyled
                    style={{ marginLeft: 0 }}
                    onClick={() => setButtunText('Eu quero!')}
                    variant={
                      buttunText === 'Eu quero!'
                        ? 'outline-primary'
                        : 'outline-secondary'
                    }
                  >
                    Eu quero!
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={() => setButtunText('Compre agora')}
                    variant={
                      buttunText === 'Compre agora'
                        ? 'outline-primary'
                        : 'outline-secondary'
                    }
                  >
                    Compre agora
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={() => setButtunText('Saiba mais')}
                    variant={
                      buttunText === 'Saiba mais'
                        ? 'outline-primary'
                        : 'outline-secondary'
                    }
                  >
                    Saiba mais
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={() => setButtunText('Visitar')}
                    variant={
                      buttunText === 'Visitar'
                        ? 'outline-primary'
                        : 'outline-secondary'
                    }
                  >
                    Visitar
                  </ButtonStyled>
                </GroupButton>
              </RowForm>
              <RowForm>
                <Label>Período</Label>
                <DatePickerStyled
                  selectsRange
                  isClearable
                  startDate={startPeriodBetweenDates}
                  endDate={endPeriodBetweenDates}
                  placeholderText="Período entre datas"
                  onChange={(date: [Date | null, Date | null]) =>
                    date && setPeriodBetweenDates(date)
                  }
                  minDate={today}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  withPortal
                />
              </RowForm>
              <RowForm>
                <Label>URL botão de ação</Label>
                <InputStyled
                  name="buttunUrl"
                  type="text"
                  value={buttunUrl}
                  onChange={(e: any) => setButtunUrl(e.target.value)}
                />
              </RowForm>
            </Context>
          </Col>
          <Col xs={4}>
            <AdsExample />
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Atenção!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Realmente deseja deletar o campanha {title}?</Modal.Body>
          <Modal.Footer>
            <ButtonStyled variant="secondary" onClick={handleClose}>
              Cancelar
            </ButtonStyled>
            <ButtonStyled variant="danger" onClick={handleDeleteNotification}>
              Deletar
            </ButtonStyled>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default NotificationsDetails;
