import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { theme } from '../../global/styles/theme';

import { ReactComponent } from '../../assets/svgs/company.svg';

export const Container = styled.div`
  width: 100%;
  margin: 4.5rem auto;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
`;

export const Context = styled(Row)`
  width: 100%;
  margin: 1rem auto;

  @media (max-width: 1060px) {
    justify-content: center;
  }
`;

export const Card = styled(Col)`
  cursor: pointer;
  width: 100%;
  max-width: 18.22222rem;
  height: 20rem;
  border-radius: 5px;
  margin: 1rem;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
  background-color: ${theme.colors.white};
  transition: 0.3s;
  path {
    fill: #8f8f8f;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  :hover {
    margin-top: 0.9rem;
    box-shadow: 0px 9px 10px 3px rgb(0 0 0 / 10%);
    transition: 0.3s;
  }
  :hover ellipse {
    fill: #6f549f;
    transition: 0.5s;
  }
  :hover path {
    fill: #6f549f;
    transition: 0.5s;
  }
`;

export const IconCompany = styled(ReactComponent)`
  width: 6rem;
`;

export const TitleCard = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  max-width: 250px;
  margin-top: -1rem;
  margin-bottom: 4rem;
  color: ${theme.colors.text.title};
`;

export const DescriptionCard = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  color: ${theme.colors.text.title};
`;
