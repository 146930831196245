import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonDiv, SkeletonContext } from './styles';

const SkeletonList = () => {
  return (
    <div className="skeleton-container">
      <div
        className="skeleton-title"
        style={{ marginBottom: '32px', display: 'flex', marginTop: '20px' }}
      >
        <Skeleton
          height={20}
          width={150}
          style={{
            marginTop: '20px',
          }}
        />
      </div>
      <SkeletonDiv
        style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          maxWidth: '100%',
        }}
      >
        <SkeletonContext
          style={{
            width: '100%',
            flex: '1 0 0%',
            maxWidth: '100%',
            textAlign: 'center',
          }}
        >
          <Skeleton
            style={{
              width: '450px',
              height: '40px',
            }}
          />
          <Skeleton
            style={{
              width: '450px',
              height: '100px',
            }}
          />
        </SkeletonContext>

        <SkeletonContext
          style={{
            width: '100%',
            flex: '1 0 0%',
            maxWidth: '100%',
            textAlign: 'center',
          }}
        >
          <Skeleton
            style={{
              width: '280px',
              height: '370px',
            }}
          />
        </SkeletonContext>
      </SkeletonDiv>

      <SkeletonDiv
        style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          maxWidth: '100%',
        }}
      >
        <SkeletonContext
          style={{
            width: '100%',
            flex: '1 0 0%',
            margin: '15px 3rem 2rem 0rem',
          }}
        >
          <Skeleton
            style={{
              width: '100%',
              height: '240px',
            }}
          />
        </SkeletonContext>
      </SkeletonDiv>

      <SkeletonDiv
        style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          maxWidth: '100%',
        }}
      >
        <SkeletonContext
          style={{
            width: '100%',
            flex: '1 0 0%',
            margin: '15px 3rem 2rem 0rem',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Skeleton
            style={{
              width: '95%',
              height: '200px',
            }}
          />
          <Skeleton
            style={{
              width: '95%',
              height: '200px',
            }}
          />
          <Skeleton
            style={{
              width: '95%',
              height: '200px',
            }}
          />
        </SkeletonContext>
      </SkeletonDiv>
    </div>
  );
};

export default SkeletonList;
